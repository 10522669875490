const SkeletonLoader = ({ size }) => {
  return (
    <div
      className={`flex mt-[6vw] justify-center items-center animate-pulse bg-gray-300 rounded-lg p-4 ${
        size === "half"
          ? "w-[50%] h-[50%]"
          : size === "quarter"
          ? "w-[25%] h-[25%]"
          : size === "full"
          ? "w-full h-full"
          : "w-auto h-auto"
      }`}
    >
      {/* <div className="bg-gray-400 h-40 w-full rounded-lg"></div>
      <div className="bg-gray-400 h-4 w-3/4 rounded-lg mt-4"></div>
      <div className="bg-gray-400 h-4 w-1/2 rounded-lg mt-2"></div>
      <div className="bg-gray-400 h-4 w-3/4 rounded-lg mt-2"></div>
      <div className="bg-gray-400 h-4 w-1/3 rounded-lg mt-2"></div> */}
      <span className="font-PretendardB fontTitleL w-fit">Now Loading...</span>
    </div>
  );
};

export default SkeletonLoader;
