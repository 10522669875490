import Slider from "react-slick";
import "./slick.css";
import "./slick-theme.css";

const Carousel = (props) => {
  const options = {
    ...props.options,
  };
  return (
    <div className={`${props.className}`} id={`${props.id || ""}`}>
      <Slider {...options}>{props.children}</Slider>
    </div>
  );
};

export default Carousel;
