import { useLoaderData } from "react-router-dom";

const NewsPage = () => {
  const loaderData = useLoaderData();
  const params = new URLSearchParams(window.location.search);
  const currentPage = params.get("page");

  console.log(currentPage);
  return (
    <div className="font-PretendardM grid grid-cols-[5%_90%_5%] md:grid-cols-[17%_66%_17%] bg-[#151515]">
      <div />
      <div className="flex flex-col py-[5rem] px-[.5rem] w-full h-screen gap-8 overflow-scroll scrollbar-hide">
        <div className="flex flex-col">
          <span className="fontTitleM font-PretendardB flex text-transparent bg-clip-text bg-gradient-to-br from-white from-45% to-[#9a9aa2] to-90%">
            News Title{currentPage}
          </span>
          <span className="text-white fontDescM mt-[1rem]">date</span>
          <span className="text-white fontDescM mt-[1rem]">category</span>
          <div className="bg-white w-full h-fit min-h-[50rem] my-[4rem] rounded-xl overflow-y-scroll scrollbar-hide"></div>
        </div>
      </div>
      <div />
    </div>
  );
};

export default NewsPage;
