import ContentsBlock from "../components/ContentsBlock";
import FadeIn from "../components/FadeIn";
import { Suspense, useEffect, useRef, useState } from "react";
import { useStateStore } from "../utils/zustandStore";
import { useLoaderData } from "react-router-dom";
import SkeletonLoader from "../components/SkeletonLoader";
import Contents from "./Contents";
import "../components/slick.css";
import "../components/slick-theme.css";
import Carousel from "../components/Carousel";
import LogoSlider from "../components/LogoSlider";
import LoadingScreen from "../components/LoadingScreen";

const Home = () => {
  const loaderData = useLoaderData();
  const logosArr = loaderData?.logosArr;
  const { languageState } = useStateStore();

  const workflowArr = [
    {
      uri: `${process.env.PUBLIC_URL}/videos/teaching-1.mp4`,
      text: `${languageState === "kr" ? "모션 티칭" : "Motion Teaching"}`,
    },
    {
      uri: `${process.env.PUBLIC_URL}/videos/teaching-2.mp4`,
      text: `${languageState === "kr" ? "공정 시뮬레이션 체크" : "Simulation"}`,
    },
    {
      uri: `${process.env.PUBLIC_URL}/videos/teaching-3.mp4`,
      text: `${languageState === "kr" ? "수작업 자동화 실행" : "Auto Execution"}`,
    },
  ];
  const [browserWidth, setBrowserWidth] = useState(window?.innerWidth);

  useEffect(() => {
    const url = window.location.href;

    if (url.includes("#contact")) {
      const contact = document.getElementById("contact");
      if (contact) {
        window.scrollTo({
          top: document.getElementById("contact")?.getBoundingClientRect().bottom + window.scrollY,
          behavior: "instant",
        });
      }
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setBrowserWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  }, []);

  const mediaQueryH =
    "h-screen min-h-[20rem] max-h-[40rem] 1.5xs:min-h-[30rem] 1.5xs:max-h-[50rem] md:min-h-[15rem] md:max-h-[35rem] xl:min-h-[28rem] xl:max-h-[48rem] 4xl:min-h-[67.5rem] 4xl:max-h-[80rem] 5xl:min-h-[90rem] 5xl:max-h-[100rem]";
  const carouselOptions = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    fade: true,
    arrows: false,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    pauseOnFocus: true,
    draggable: true,
    dotsClass: "slick-dots slick-thumb",
    lazyLoad: "anticipated",
  };

  return (
    <main className={`flex flex-col w-full h-fit bg-[#151515] relative font-PretendardM`}>
      <div className={`w-full min-h-fit relative mx-auto`}>
        <div className={`relative w-full ${mediaQueryH} overflow-y-hidden text-ellipsis`}>
          <Carousel className="mainSlider w-full h-full relative" options={carouselOptions}>
            {[
              {
                uri: `${process.env.PUBLIC_URL}/images/Image1.png`,
                title: { kr: "PORTAL301", en: "PORTAL301" },
                description: {
                  kr: "전문가의 모션 데이터 기반\n로봇 자동화 솔루션을 제공합니다.",
                  en: "We provide robotic automation solutions based on expert motion data.",
                },
              },
              {
                uri: `${process.env.PUBLIC_URL}/images/Image2.png`,
                title: { kr: "혁신적인 로봇 UI/UX", en: "UI/UX Innovator for Robotics Industry" },
                description: {
                  kr: "사용자 경험을 극대화한 로봇 제어 기술을 구현합니다.",
                  en: "We focus on user-centered design to make robotic technology more accessible and user-friendly.",
                },
              },
            ].map((item, index) => (
              <Suspense key={index} fallback={<SkeletonLoader size={"full"} />}>
                <div key={index} className={`relative flex flex-col w-full h-full pt-[2.5rem]`}>
                  <picture className="absolute top-0 left-0 w-full h-full z-0">
                    <img
                      src={item.uri}
                      alt=""
                      className="w-full bg-transparent h-full object-cover select-none"
                    />
                  </picture>
                  <ContentsBlock
                    className={`bg-transparent absolute top-[calc(12.1875%_-_6.25rem)] 1.5xs:top-[calc(30.75%_-_6.25rem)] md:top-[calc(31.25%_-_7.5rem)] xl:top-[calc(30.57142857142857%_-_10rem)] 4xl:top-[calc(32%_-_10rem)] 5xl:top-[calc(35%_-_10rem)]`}
                    style={{ marginTop: 0 }}
                  >
                    <div className="select-none 1.5xs:gap-[.625rem] flex flex-col z-10 w-full h-fit gap-[.5rem] lg:gap-5">
                      <span
                        className={`fontTitleL font-PretendardB w-fit z-10 font-bold text-transparent bg-clip-text bg-gradient-to-l bg-white`}
                      >
                        {item.title[languageState]}
                      </span>
                      <span
                        className={`fontDescL 5xl:fontDesc select-none z-10 text-white break-keep`}
                      >
                        {item.description[languageState]}
                      </span>
                    </div>
                  </ContentsBlock>
                </div>
              </Suspense>
            ))}
          </Carousel>
        </div>
        <div className="gradientToTop absolute bottom-0 left-0 w-full h-[2%] z-[1]" />
      </div>
      <ContentsBlock>
        <div className="flex w-full mt-[5rem]">
          <span
            className={`w-fit mx-auto fontTitleM font-PretendardB self-center text-center tracking-tight break-keep bg-clip-text text-transparent bg-gradient-to-br from-white from-[44.98%] to-[#fff] to-[90.4%]`}
          >{`${
            languageState === "kr" ? "사용자 친화적인 로봇 제어" : "User-Friendly Robotic Control"
          }`}</span>
        </div>
        <div className="flex flex-col gap-[5rem] md:gap-[11.25rem] w-full mt-[7.5em] md:mt-[8.75rem] xl:mt-[11.25rem]">
          {[
            {
              order: "backward",
              title: { kr: "손 쉬운 사용", en: "Easy to Use" },
              description: {
                kr: "비전문가도 손쉽게 로봇의 움직임을 설정할 수 있습니다.",
                en: "Even non-experts can easily configure the movements of the robot.",
              },
              uri: `${process.env.PUBLIC_URL}/videos/use-ex.mp4`,
            },
            {
              order: "forward",
              title: { kr: "간편한 로직 변경", en: "Comfortable Adjustment" },
              description: {
                kr: "단순 제스쳐를 통해 로봇의 움직임을 설정하여 간편하게 기존 로직을 변경할 수 있습니다.",
                en: "Effortlessly Control Your Robot with Just a Gesture",
              },
              uri: `https://staticsrc.s3.ap-northeast-2.amazonaws.com/video/simple2.mp4`,
            },
            {
              order: "backward",
              title: { kr: "동작 동기화 제어", en: "Synchronized Motion Control" },
              description: {
                kr: "로봇의 움직임을 사용자의 움직임과 함께 동기화하여 제어하는 시스템을 제공합니다.",
                en: "We offer a system that synchronizes and controls the robot's movements to match the user's actions.",
              },
              uri: `https://staticsrc.s3.ap-northeast-2.amazonaws.com/video/motoion-syncro.mp4`,
            },
          ].map((item, index) => (
            <div
              key={index}
              className={`grid grid-cols-1 md:grid-cols-[repeat(2,minmax(0,1fr))] gap-y-[1.25rem] md:gap-x-[5rem] 4xl:gap-x-[9.375rem] md:items-center mx-auto`}
            >
              {item.order === "forward" ? (
                <>
                  <div className="w-full">
                    <FadeIn distance={50} directionFrom="down" speed={2} location={`center`}>
                      <Suspense fallback={<SkeletonLoader />}>
                        <VideoObserver position={[50, 50]} uri={item.uri} />
                      </Suspense>
                    </FadeIn>
                  </div>
                  <div className="flex flex-col w-full max-w-[80rem] h-fit md:justify-center md:items-end">
                    <FadeIn distance={30} directionFrom="down" speed={2}>
                      <div className="flex flex-col w-fit gap-[.5rem]">
                        <span
                          className={`w-full fontTitleS font-PretendardB md:self-end break-keep bg-clip-text text-transparent bg-gradient-to-br from-white from-[44.98%] to-[#fff] to-[90.4%]`}
                        >
                          {item.title[languageState]}
                        </span>
                        <span
                          className={`fontDescM w-full tracking-tight text-[#85929F] text-left md:text-left break-keep `}
                        >
                          {item.description[languageState]}
                        </span>
                      </div>
                    </FadeIn>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full h-fit">
                    <FadeIn distance={50} directionFrom="down" speed={2} location={`center`}>
                      <Suspense fallback={<SkeletonLoader />}>
                        <VideoObserver position={[50, 50]} uri={item.uri} />
                      </Suspense>
                    </FadeIn>
                  </div>
                  <div className="flex flex-col md:row-span-full w-full max-w-[80rem] h-fit md:justify-center md:items-start">
                    <FadeIn distance={30} directionFrom="down" speed={2}>
                      <div className="flex flex-col w-fit">
                        <span
                          className={`w-full fontTitleS font-PretendardB md:self-start text-left break-keep bg-clip-text text-transparent bg-gradient-to-br from-white from-[44.98%] to-[#9a9aa2] to-[90.4%]`}
                        >
                          {item.title[languageState]}
                        </span>
                        <span
                          className={`fontDescM w-full tracking-tight text-[#85929F] text-left break-keep mt-2`}
                        >
                          {item.description[languageState]}
                        </span>
                      </div>
                    </FadeIn>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </ContentsBlock>
      <ContentsBlock>
        <div className="flex flex-col w-full mt-[5rem] mb-[5rem] gap-[7.5rem] md:gap-[8.75rem] xl:gap-[11.25rem]">
          <div className="font-PretendardBL flex justify-center">
            <h1
              className={`w-full fontTitleM self-center text-center tracking-tight break-keep bg-clip-text text-transparent bg-gradient-to-br from-white from-[44.98%] to-[#fff] to-[90.4%]`}
            >{`${
              languageState === "kr"
                ? "자동화된 작업 흐름 시각화"
                : "Automated Workflow Visualization"
            }`}</h1>
          </div>
          <Suspense fallback={<SkeletonLoader />}>
            <div className="flex flex-col w-full justify-center md:mx-auto md:flex-row gap-[5rem] md:gap-[2rem] xl:gap-[4rem] 4xl:gap-[5rem] 5xl:gap-x-[7.5rem] 4xl:justify-center">
              {workflowArr.map((item, index) => (
                <div
                  key={index}
                  className={`font-PretendardM 4xl:w-full h-auto grid grid-rows-1 grid-cols-none 4xl:grid-rows-none 4xl:grid-cols-[1fr]`}
                >
                  <div
                    key={index}
                    className="flex flex-col h-fit 4xl:h-full items-center gap-[1.25rem] xl:gap-[2.5rem]"
                  >
                    <video
                      className="w-full h-fit rounded-lg"
                      preload="none"
                      autoPlay
                      playsInline
                      loop
                      muted
                    >
                      <source src={item.uri} />
                    </video>
                    <span
                      className={`fontDescL2 text-center 4xl:my-auto break-keep bg-clip-text text-transparent bg-gradient-to-br from-white from-[44.98%] to-[#fff] to-[90.4%]`}
                    >
                      {item.text}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </Suspense>
        </div>
      </ContentsBlock>
      {/* <div className="relative w-full h-screen max-h-[40.625rem] md:max-h-[46rem] xl:max-h-[55.625rem] 4xl:max-h-[67.5rem] 5xl:min-h-[110.75rem] pt-[6.25rem] md:pt-[7.5rem] xl:pt-[10rem] 4xl:pt-[10rem] flex flex-col overflow-hidden">
        <div className="gradientToBottom absolute top-0 left-0 w-full h-[100%] z-[1]" />
        <div className="gradientToTop absolute top-0 left-0 w-full h-[100%] z-[1]" />
        <div className="absolute top-[11%] left-0 w-full flex flex-col gap-[1rem] z-[3] items-center">
          <span className="font-PretendardB fontTitleL2 text-Static-White z-[10]">
            SyncRo가 곧 출시됩니다
          </span>
          <span className="fontDescM2 text-Interaction-Inactive z-[1]">2024.08.15 OPEN</span>
        </div>
        <picture className="absolute w-full h-full top-0 left-0">
          <img
            src={`${process.env.PUBLIC_URL}/images/syncro-plate.png`}
            alt=""
            className="w-full h-full object-cover object-center"
          />
        </picture>
        <picture className={`relative w-full h-full flex justify-center items-center z-[2]`}>
          <img
            src={`${process.env.PUBLIC_URL}/images/syncro.png`}
            alt=""
            className="w-full max-w-[15rem] 1.5xs:max-w-[20.25rem] md:max-w-[30rem] xl:max-w-[35.5rem] 4xl:max-w-[40.625rem] 5xl:max-w-[63.5rem] mb-[3%] object-cover object-center animation-bounce"
          />
        </picture>
      </div> */}
      <ContentsBlock>
        <div className="relative flex flex-col w-full h-fit mx-auto overflow-hidden">
          <div className="flex flex-col items-center w-full">
            <span
              className={`fontTitleM font-PretendardB break-keep self-center text-center text-transparent bg-clip-text bg-gradient-to-r from-white to-[#fff]`}
            >
              {`${languageState === "kr" ? "튜토리얼 및 레퍼지토리" : "Tutorials & Repositories"}`}
            </span>
          </div>
          <div className="flex w-full items-center justify-center mt-[6.25rem] md:mt-[7.5rem] xl:mt-[10rem]">
            <div className="bg-Line-Normal-Strong rounded-lg w-full max-w-[160rem] flex flex-col md:flex-row-reverse gap-[2rem] md:gap-[0rem] p-[2rem_1rem] md:p-[1.25rem] 4xl:p-[5rem_2.5rem]">
              <div className="flex items-center justify-center w-fit mx-auto md:mt-auto ">
                <picture className="w-fit rounded-full">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/github3.png`}
                    alt=""
                    className="flex w-[1.5rem] md:min-w-[2rem] xl:min-w-[5rem] 4xl:min-w-[6.25rem] object-cover select-none"
                  />
                </picture>
              </div>
              <div className="w-full flex flex-col gap-[2rem] xl:gap-[5rem] 4xl:gap-[7.5rem]">
                {[
                  {
                    url: "https://github.com/portal301/ur-python-tutorial",
                    description: "설명",
                    title: "UR Python Tutorial",
                  },
                  {
                    url: "https://github.com/portal301/robot-vision-tutorial",
                    description: "설명",
                    title: "Robot Vision Tutorial",
                  },
                  {
                    url: "https://github.com/portal301/honeytips",
                    description: "설명",
                    title: "Honey TIPS",
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="relative w-full md:w-fit h-fit flex md:flex-col justify-between"
                  >
                    <span className={`fontDescM2 text-white font-PretendardB w-fit max-w-full`}>
                      <span
                        className={`mr-[.5rem] w-fit ${
                          index % 3 === 0
                            ? "text-Accent-RedOrange"
                            : index % 3 === 1
                            ? "text-Accent-Lime"
                            : index % 3 === 2
                            ? "text-Accent-Purple"
                            : "text-Accent-LightBlue"
                        }`}
                      >
                        •
                      </span>
                      {`${item.title}`}
                    </span>
                    <a
                      key={index}
                      href={item.url}
                      className="fontDescS flex flex-col cursor-pointer z-20 break-all w-fit max-w-full"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {window.innerWidth < 768 ? (
                        <span className="absolute flex justify-end w-full right-0 top-0">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/Chevron Right.png`}
                            alt=""
                            className={`h-[1.5rem] brightness-[.7] hover:brightness-100`}
                          />
                        </span>
                      ) : (
                        <span
                          title={item.url}
                          className={`text-Accent-LightBlue w-fit px-3 xl:px-[2rem] max-w-full truncate`}
                        >{`${item.url}`}</span>
                      )}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ContentsBlock>
      {/* <Contents /> */}
      <div
        className={`relative w-full min-w-fit mx-auto bg-[#151515] pt-[6.25rem] md:pt-[7.5rem] xl:pt-[10rem] 4xl:pt-[10rem] `}
      >
        <div className={`flex flex-col justify-center items-center w-full min-h-fit relative`}>
          <span
            className={`fontTitleM font-PretendardB w-fit text-transparent mt-[12.5rem] mb-[10rem] bg-clip-text bg-gradient-to-r from-white to-[#fff] mx-auto select-none`}
          >
            {`${languageState === "kr" ? "파트너" : "Partners"}`}
          </span>
        </div>
        <LogoSlider logosArr={logosArr} />
      </div>
      <ContentsBlock className={`pb-[7.5rem]`}>
        <div
          id="contact"
          className={`flex flex-col justify-center items-center w-full min-h-fit relative`}
        >
          <span
            className={`fontTitleM font-PretendardB w-fit text-transparent bg-clip-text bg-gradient-to-r from-white to-[#fff] mx-auto select-none`}
          >
            Contact Us
          </span>
          <span
            className={`fontDescM w-fit text-center text-transparent mt-[2rem] bg-clip-text bg-gradient-to-r from-white to-[#fff] mx-auto select-none`}
          >{`${
            languageState === "kr"
              ? "이메일: contact@portal301.com"
              : "For all questions and requests: contact@portal301.com"
          }`}</span>
        </div>
      </ContentsBlock>
    </main>
  );
};

export default Home;

const ArrowImage = ({ direction, size }) => {
  return (
    <>
      {direction === "down" ? (
        <div className={`m-auto w-[${size * 0.25}rem] h-[${size * 0.25}rem] z-0 rounded-full`}>
          <picture className="w-full h-full z-0">
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                className="text-white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 14l7 7m0 0l7-7m-7 7V3"
              />
            </svg>
          </picture>
        </div>
      ) : direction === "right" ? (
        <div
          className={`mx-auto md:mt-[5%] w-[${size * 0.25}rem] h-[${
            size * 0.25
          }rem] z-0 rounded-full rotate-90`}
        >
          <picture className="w-full h-full z-0">
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                className="text-white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 10l7-7m0 0l7 7m-7-7v18"
              />
            </svg>
          </picture>
        </div>
      ) : direction === "left" ? (
        <div
          className={`mx-auto md:mt-[5%] w-[${size * 0.25}rem] h-[${
            size * 0.25
          }rem] z-0 rounded-full -rotate-90`}
        >
          <picture className="w-full h-full z-0">
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                className="text-white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 10l7-7m0 0l7 7m-7-7v18"
              />
            </svg>
          </picture>
        </div>
      ) : (
        <div
          className={`mx-auto md:mt-[5%] w-[${size * 0.25}rem] h-[${
            size * 0.25
          }rem] z-0 rounded-full`}
        >
          <picture className="w-full h-full z-0">
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                className="text-white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 10l7-7m0 0l7 7m-7-7v18"
              />
            </svg>
          </picture>
        </div>
      )}
    </>
  );
};

const Logos = ({ logosArr }) => {
  return (
    <div className="flex flex-wrap w-[95%] h-fit items-center gap-2 mt-10 p-1 md:p-4 bg-[rgba(217,217,217)] rounded-lg">
      {logosArr.map((item, index) => (
        <picture
          key={index}
          className={`${
            item.name === "Naver Cloud"
              ? "p-4 md:p-8"
              : item.name === "SEMS"
              ? "p-0"
              : item.name === "우리 금융 그룹"
              ? "p-0"
              : "p-1 md:p-4"
          } flex justify-center items-center w-[6rem] md:w-[16rem] h-[3.375rem] md:h-[9rem] mx-auto bg-[rgba(217,217,217)] rounded-lg`}

          // className="flex w-[31%] md:w-[16%] 3xl:w-[12%] mx-auto h-fit bg-[rgba(217,217,217)] p-1 rounded-lg"
        >
          <img
            src={item.url}
            alt={item.name}
            title={item.name}
            className="w-[6rem] md:w-[16rem] h-[3.375rem] md:h-[9rem] object-contain select-none"
            draggable={false}
          />
        </picture>
      ))}
    </div>
  );
};
// 16:9=6:x, x=6*(9/16)=3.375
/**
 * @param {object} props - The props object.
 * @param {string} props.size - full, half, quarter
 * @param {string} props.bgColor - The background color of the card.
 * @param {ReactNode} props.children - The children to be rendered inside the component.
 * @returns
 */
const Card = ({ size, bgColor, children }) => {
  return (
    <div
      style={{ backgroundColor: bgColor }}
      className={`flex flex-col justify-between flex-wrap rounded-xl gap-2 text-[10%] text-white select-none max-w-full ${
        size === "full"
          ? "w-[40rem] h-full"
          : size === "half"
          ? "min-w-[20rem] w-[20rem] h-full"
          : size === "quarter"
          ? "min-w-[20rem] w-[20rem] h-[12.5rem]"
          : "w-[40rem] h-full"
      }`}
    >
      <div>
        <span>{children}</span>
      </div>
    </div>
  );
};

const Observer = ({ children }) => {
  const ref = useRef();
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          console.log("intersecting");
        }
      },
      { threshold: 0.5 }
    );
    observer.current.observe(ref.current);

    return () => {
      observer.current.disconnect();
    };
  }, []);

  return <div ref={ref}>{children}</div>;
};

/**
 * @param {Object}  props - The props object.
 * @param {Array} props.position - The position of the video. [x, y]  x, y are in percentage.
 * @param {string} props.uri - The uri of the video.
 * @returns
 */
const VideoObserver = ({ position, uri }) => {
  const videoRef = useRef();
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const playPromise = videoRef.current.play();
          if (playPromise !== undefined) {
            playPromise
              .then((_) => {})
              .catch((error) => {
                console.error(error);
              });
          } else {
            videoRef.current.play();
          }
        } else {
          const playPromise = videoRef.current?.play();
          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                videoRef.current.pause();
              })
              .catch((error) => console.error(error));
          } else {
            videoRef.current.pause();
          }
        }
      },
      { threshold: 0.5 }
    );
    observer.current.observe(videoRef.current);

    return () => {
      observer.current.disconnect();
    };
  }, []);

  return (
    <video
      ref={videoRef}
      className={`rounded-lg object-cover object-[${position[0]}%_${position[1]}%]`}
      title="video"
      preload="none"
      loop
      controls
      autoPlay
      playsInline
      muted
    >
      <source src={uri} />
    </video>
  );
};
