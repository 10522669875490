import { Suspense, useEffect, useRef, useState } from "react";
import ImageScroller from "../components/ImageScroller";
import Carousel from "../components/Carousel";
import SkeletonLoader from "../components/SkeletonLoader";
import MonoColorSlider from "../components/MonoColorSlider";
import LoadingScreen from "../components/LoadingScreen";
import { useStateStore, useIsLoadingStore } from "../utils/zustandStore";

const Product = () => {
  const [images, setImages] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const { isLoading } = useIsLoadingStore();
  const { languageState } = useStateStore();

  useEffect(() => {
    fetchImages();
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.addEventListener("resize", handleResize);
    return () => {
      window?.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const fetchImages = async () => {
    const imagesArr = Array.from(
      { length: 90 },
      (_, i) => `${process.env.PUBLIC_URL}/images/motion/Robot2-3__000${i < 10 ? "0" + i : i}.jpg`
    );
    const fetchedImages = await Promise.all(
      imagesArr.map(async (image) => {
        const fetchedImage = await fetch(image);
        const blob = await fetchedImage.blob();
        return URL.createObjectURL(blob);
      })
    );
    setImages(fetchedImages);
  };

  const stickyPanelArr = [
    {
      bg: `bg-No_Pg`,
      title: `이제는 코딩이 필요 없는 시대\n작업자의 행동 실시간 생성.`,
      description: () => (
        <span>
          작업자의 작업 경로를 스캔하는 컨트롤러
          <br />
          하나 만으로
          <strong className="font-PretendardBL">프로그래밍 작업 필요 없이</strong>
          <br />
          로봇 로직을 실시간으로 생성합니다.
        </span>
      ),
    },
    {
      bg: `bg-monitor`,
      title:
        languageState === "kr"
          ? `쉬운 GUI 관리자 화면으로\n작업 관리를 한 눈에.`
          : `Easily Manage Tasks \nat a Glance with an Intuitive GUI Admin Interface`,
      description: () => (
        <span>
          현장 작업자 또는 오퍼레이터들이
          <br />
          로그램을 손쉽게 조작할 수 있도록
          <br />
          <strong className="font-PretendardBL">사용자 중심의 간편한 화면 제어 시스템을</strong>
          <br />
          제공합니다.
        </span>
      ),
    },
    // {
    //   bg: `bg-robotArm`,
    //   title: `전 세계 1% 숙련공\n노하우 자동화 학습.`,
    //   description: () => (
    //     <span>
    //       전 세계 1%만이 구사할 수 있는 노하우가 담긴
    //       <br />
    //       숙련공의 수작업 과정을
    //       <strong className="font-PretendardBL">SyncRo로 간단하게 스캔해</strong>
    //       <br />
    //       언제든 작업 환경 자동화가 가능합니다.
    //     </span>
    //   ),
    // },
  ];
  // const motionImages = {
  //   images: Array.from(
  //     { length: 90 },
  //     (_, i) => `${process.env.PUBLIC_URL}/images/motion/Robot2-3__000${i < 10 ? "0" + i : i}.png`
  //   ),
  // };

  return (
    <main
      id="product"
      className={`font-PretendardM break-keep flex flex-col w-full h-fit min-h-screen relative gap-[.1vw]`}
    >
      <Section className={`bg-syncRoBackgroundBlue bg-no-repeat bg-cover bg-center`}>
        <div className="relative">
          <div className="relative flex justify-between">
            <picture className={`w-fit h-full p-[.5rem_1rem] object-contain object-center`}>
              {/* <img
                src="/images/logo2.svg"
                alt=""
                className="w-full h-full object-cover object-center"
              /> */}
            </picture>
            {/* <button className="flex justify-center items-center p-[.25rem_1rem] bg-black rounded-full">
              <div className="flex items-center gap-[.5rem]">
                <picture>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="10" height="10" rx="5" fill="white" />
                  </svg>
                </picture>
                <span className="text-white whitespace-nowrap">
                  {languageState === "kr" ? "문의하기" : "Contact Us"}
                </span>
              </div>
            </button> */}
          </div>
          <div className="relative translate-y-[5rem]">
            <div className="text-white text-[4.75vw] w-[80%]">
              {languageState === "kr" ? (
                <>
                  <strong className="font-PretendardBL">{`작업자의 행동 데이터를 학습하는 `}</strong>
                  <span>로봇 자동화 솔루션</span>
                  <strong className="font-PretendardBL">{` SyncRo`}</strong>
                </>
              ) : (
                <>
                  <strong className="font-PretendardBL">{`SyncRo: `}</strong>
                  <span>Robotic Automation Solution</span>
                  <strong className="font-PretendardBL">{` Learning from Worker Behavior Data`}</strong>
                </>
              )}
            </div>
            <div className="relative translate-y-[2.5rem] mx-auto bg-[#d9d9d9] w-full aspect-[9/12.5] md:aspect-PC rounded-[1em] overflow-hidden shadow-[3px_3px_10px_rgba(50,50,50,0.8)]">
              <Suspense fallback={<SkeletonLoader />}>
                <picture className="w-full h-full">
                  <img
                    src={`${
                      isMobile
                        ? "https://staticsrc.s3.ap-northeast-2.amazonaws.com/images/SyncRo.web/product/1_Mobile%403x.jpg"
                        : "https://staticsrc.s3.ap-northeast-2.amazonaws.com/images/SyncRo.web/product/1_PC%403x.jpg"
                    }`}
                    alt=""
                    className="w-full h-full object-cover object-center"
                  />
                </picture>
              </Suspense>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        {images.length !== 0 ? (
          <ImageScroller
            isMobile={isMobile}
            imageScrollerHeight={images.length * 100}
            images={images}
            width={900}
            height={900}
          />
        ) : (
          <SkeletonLoader />
        )}
      </Section>
      <MonoColorSlider imagesArr={null} />
      <Section>
        <div className="flex flex-col gap-[8vw] md:gap-[7.5rem] break-keep">
          <div className="flex flex-col md:flex-row w-fit mx-auto md:gap-[10vw]">
            <div className="flex flex-col gap-[5vw] md:gap-[1vw]">
              <span className="w-fit text-[2.75vw] md:text-[1.375vw] uppercase font-PretendardBL text-[#717071]">
                process
              </span>
              {languageState === "kr" ? (
                <>
                  <span className="md:w-[18vw] text-[6vw] md:text-[3vw] font-PretendardBL">
                    수작업 환경 원스톱 자동화.
                  </span>
                </>
              ) : (
                <>
                  <span className="md:w-[22vw] text-[6vw] md:text-[2.875vw] font-PretendardBL leading-[8vw] md:leading-[4vw]">
                    One-Stop Automation for Manual Work Environments
                  </span>
                </>
              )}
            </div>
            {languageState === "kr" ? (
              <>
                <span className="md:w-[40vw] text-[3.75vw] md:text-[1.875vw] mt-[3vw] md:mt-[3vw]">
                  작업자가 단 한 번의 시연 만으로도{" "}
                  <strong className="font-PretendardBL">
                    로봇은 실시간으로 행동을 기록해 작업 환경을 최적화
                  </strong>{" "}
                  합니다.
                  <br /> 관리자 화면에서 설정한 작업 범위를 실시간으로 확인한 후 작업시작 버튼을
                  누르면{" "}
                  <strong className="font-PretendardBL">
                    SyncRo를 활용한 수작업 자동화 세상이
                  </strong>{" "}
                  펼쳐집니다.
                </span>
              </>
            ) : (
              <>
                <span className="md:w-[40vw] text-[3.75vw] md:text-[1.875vw] leading-[6vw] md:leading-[3vw] mt-[3vw] md:mt-[3vw]">
                  With just a single demonstration,{" "}
                  <strong className="font-PretendardBL">
                    the robot records actions in real-time to optimize the work environment.{" "}
                  </strong>
                  After verifying the work scope set in the application in real-time, click the
                  start button to enter a world of{" "}
                  <strong className="font-PretendardBL">
                    manual automation powered by SyncRo.
                  </strong>
                </span>
              </>
            )}
          </div>
          <div className="grid grid-rows-3 md:grid-cols-3 md:grid-rows-none gap-[2.5vw] whitespace-pre-wrap">
            {[
              {
                title:
                  languageState === "kr" ? `모션기반 로봇 티칭` : "Motion-Based Robot Teaching",
                description: () =>
                  languageState === "kr" ? (
                    <>
                      <span>
                        로봇의 프로그래밍 없이 숙련공의
                        <br />
                        <strong className="font-PretendardBL">행동 패턴을 실시간으로 인식</strong>해
                        <br />
                        로직을 생성합니다.
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        Generates logic by recognizing experts'{" "}
                        <strong className="font-PretendardEB">
                          behavior patterns in real-time
                        </strong>{" "}
                        without the need for robot programming.
                      </span>
                    </>
                  ),
                bg: "bg-ProcessTraining bg-no-repeat bg-cover bg-center",
                icon: "https://staticsrc.s3.ap-northeast-2.amazonaws.com/images/SyncRo.web/product/4_Process_Training+Vector.png",
              },
              {
                title: languageState === "kr" ? `공정 3D 시뮬레이션 체크` : `3D Process Simulation`,
                description: () =>
                  languageState === "kr" ? (
                    <>
                      <span>
                        로봇이 실행되기 전에
                        <br />
                        예상 작업을{" "}
                        <strong className="font-PretendardBL">
                          시뮬레이션으로
                          <br />
                          확인
                        </strong>
                        하고, <strong className="font-PretendardBL">이슈사항을 선대응</strong>
                        합니다.
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        Before the robot operation,
                        <br />
                        <strong className="font-PretendardEB">the expected tasks </strong>
                        to identify and{" "}
                        <strong className="font-PretendardEB">
                          {" "}
                          address potential issues in advance.
                        </strong>
                      </span>
                    </>
                  ),
                bg: "bg-ProcessSimulation bg-no-repeat bg-cover bg-center",
                icon: "https://staticsrc.s3.ap-northeast-2.amazonaws.com/images/SyncRo.web/product/4_Process_Simulation+Vector.png",
              },
              {
                title:
                  languageState === "kr" ? "수작업 자동화 실행" : "Manual Automation Execution",
                description: () =>
                  languageState === "kr" ? (
                    <>
                      <span>
                        행동 데이터 기반으로
                        <br />
                        숙련공의 노하우가 이식된
                        <br />
                        로봇 자동화 작업이{" "}
                        <strong className="font-PretendardBL">
                          단 한 번의
                          <br />
                          클릭만으로 실행
                        </strong>
                        됩니다.
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        Robot automation, infused with the expertise of skilled workers through
                        behavior data, is{" "}
                        <strong className="font-PretendardEB">
                          executed with just a single click.
                        </strong>
                      </span>
                    </>
                  ),
                bg: "bg-ProcessAutomatic bg-no-repeat bg-cover bg-center",
                icon: "https://staticsrc.s3.ap-northeast-2.amazonaws.com/images/SyncRo.web/product/4_Process_Automatic+Vector.png",
              },
            ].map((item, index) => {
              return (
                <div
                  key={index}
                  className={`relative md:aspect-square flex flex-col mx-auto min-w-[50vw] md:min-w-[30%] w-full h-full text-white ${item.bg} bg-center rounded-[1em] p-[4vw] md:p-[3vw] shadow-[3px_3px_10px_rgba(50,50,50,0.8)]`}
                >
                  <picture className="absolute top-[3%] right-[3%] w-[40%] aspect-auto">
                    <img src={item.icon} alt="" />
                  </picture>
                  <span className="font-PretendardB z-[10] text-[4vw] h-[18vw] md:h-[12vw] md:text-[2vw] w-[40vw] md:max-w-[14vw] mt-[3.5vw] md:mt-[2vw]">
                    {item.title}
                  </span>
                  <span className="z-[10] text-[3vw] md:text-[1.37vw] w-[50vw] md:max-w-[20vw] md:w-full">
                    {item.description()}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="w-full flex flex-col gap-[3.75rem] mt-[4vw]">
            <span className="text-[6.75vw] md:text-[3.375vw] self-center">Supported Robots</span>
            <div className="flex w-fit gap-[2rem] md:gap-[10rem] mx-auto">
              {[
                {
                  uri: "https://staticsrc.s3.ap-northeast-2.amazonaws.com/images/SyncRo.web/Universal_robot_1.png",
                },
                {
                  uri: "https://staticsrc.s3.ap-northeast-2.amazonaws.com/images/SyncRo.web/FANUC_1.png",
                },
              ].map((item, index) => {
                return (
                  <picture key={index} className="relative w-full">
                    {index === 0 ? (
                      <img src={item.uri} alt="" className="w-full h-full object-fill" />
                    ) : (
                      <img
                        src={item.uri}
                        alt=""
                        className="w-full h-full origin-bottom-right scale-[.65]"
                      />
                    )}
                  </picture>
                );
              })}
            </div>
          </div>
        </div>
      </Section>
      <section className={`mt-[8.75rem] h-fit`}>
        <Carousel
          id="vertical"
          options={{
            dots: true,
            arrows: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            pauseOnFocus: false,
          }}
          className="w-full min-h-full h-fit"
        >
          {/* {stickyPanelArr.map((item, index) => {
            console.log(window.innerWidth < 768 ? item.bg + "M" : item.bg);
            return (
              <div
                ref={stickyRef}
                key={index}
                className={`relative aspect-[10.5/19.5] md:aspect-[16/9] ${
                  window.innerWidth < 768 ? item.bg + "M" : item.bg
                } bg-cover bg-bottom bg-no-repeat w-full h-full pt-[5.25rem] md:pt-[7.5rem]`}
              >
                <div className={`w-full h-fit`}>
                  <div className="w-fit bg-opacity-50 pl-[4.375rem] backdrop-blur-[7px] md:backdrop-blur-[10px] rounded-[1em]">
                    <div className="fontTitleS font-PretendardBL whitespace-pre-wrap">
                      <span>{item.title}</span>
                    </div>
                    <div className="fontDescM whitespace-pre-wrap mt-[2rem]">
                      {item.description()}
                    </div>
                  </div>
                </div>
              </div>
            );
          })} */}
          <div
            className={`relative aspect-[504/960] md:aspect-[16/9] ${
              window.innerWidth < 768 ? "bg-No_TeachingM" : "bg-No_Teaching"
            } bg-cover bg-bottom md:bg-right bg-no-repeat w-full h-full pt-[5.25rem] md:pt-[5vw]`}
          >
            <div className={`w-full h-fit`}>
              <div className="w-fit bg-opacity-50 pl-[8vw] rounded-[1em]">
                <div className="w-[80vw] md:w-[30vw] text-[4.5vw] md:text-[2.25vw] leading-[6vw] md:leading-[3vw] font-PretendardBL whitespace-pre-wrap">
                  <span className="drop-shadow-[2px_2px_3px_rgba(255,255,255,1)]">
                    {languageState === "kr"
                      ? `전 세계 1% 숙련공\n노하우 자동화 학습.`
                      : "Turning top 1% expertise into AI automation."}
                  </span>
                </div>
                <div className="w-[80vw] md:w-[26vw] text-[3.75vw] md:text-[1.75vw] whitespace-pre-wrap mt-[2rem]">
                  {languageState === "kr" ? (
                    <span className="drop-shadow-[2px_2px_3px_rgba(255,255,255,1)]">
                      전 세계 1%만이 구사할 수 있는 노하우가 담긴 작업자의 수작업 과정을
                      <strong className="font-PretendardBL"> SyncRo로 간단하게 스캔</strong>해
                      언제든 작업 환경 자동화가 가능합니다.
                    </span>
                  ) : (
                    <span className="w-[40vw] whitespace-pre-wrap drop-shadow-[2px_2px_3px_rgba(255,255,255,1)]">
                      <strong className="font-PretendardBL">SyncRo easily scans </strong>
                      the manual processes of the top 1% of skilled workers, enabling instant
                      automation of work environments.
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`relative aspect-[504/960] md:aspect-[16/9] ${
              window.innerWidth < 768 ? "bg-No_PgM" : "bg-No_Pg"
            } bg-cover bg-bottom md:bg-right bg-no-repeat w-full h-full pt-[5.25rem] md:pt-[5vw]`}
          >
            <div className={`w-full h-fit`}>
              <div className="w-fit bg-opacity-50 pl-[8vw] rounded-[1em]">
                <div className="w-[80vw] md:w-[30vw] text-[4.5vw] md:text-[2.125vw] leading-[6vw] md:leading-[3vw] font-PretendardBL whitespace-pre-wrap">
                  <span className="drop-shadow-[2px_2px_3px_rgba(255,255,255,1)]">
                    {languageState === "kr"
                      ? `이제는 코딩이 필요 없는 시대\n작업자의 행동 실시간 생성.`
                      : "No Code, No Scripts: Automation Powered by Your Actions"}
                  </span>
                </div>
                <div className="w-[80vw] md:w-[29vw] text-[3.75vw] md:text-[1.75vw] whitespace-pre-wrap mt-[2rem]">
                  {languageState === "kr" ? (
                    <span className="drop-shadow-[2px_2px_3px_rgba(255,255,255,1)]">
                      작업자의 작업 경로를 스캔하는 컨트롤러 하나 만으로
                      <strong className="font-PretendardBL"> 프로그래밍 작업 필요 없이</strong> 로봇
                      로직을 실시간으로 생성합니다.
                    </span>
                  ) : (
                    <span className="w-[40vw] whitespace-pre-wrap drop-shadow-[2px_2px_3px_rgba(255,255,255,1)]">
                      With just one controller that scans worker paths, generate robot logic in
                      real-time{" "}
                      <strong className="font-PretendardBL">
                        without the need for programming
                      </strong>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`relative aspect-[504/960] md:aspect-[16/9] ${
              window.innerWidth < 768 ? "bg-monitorM" : "bg-monitor"
            } bg-cover bg-bottom bg-no-repeat w-full h-full pt-[5.25rem] md:pt-[5vw]`}
          >
            <div className={`w-full h-fit`}>
              <div className="w-fit bg-opacity-50 pl-[8vw] rounded-[1em]">
                <div className="w-[80vw] md:w-[30vw] text-[4.5vw] md:text-[2.25vw] leading-[6vw] md:leading-[3vw] font-PretendardBL whitespace-pre-wrap">
                  <span className="drop-shadow-[2px_2px_3px_rgba(255,255,255,1)]">
                    {languageState === "kr"
                      ? `쉬운 GUI 관리자 화면으로\n작업 관리를 한 눈에`
                      : `Easily Manage Tasks at a Glance with an Intuitive GUI Admin Interface`}
                  </span>
                </div>
                <div className="w-[80vw] md:w-[31vw] text-[3.75vw] md:text-[1.875vw] whitespace-pre-wrap mt-[2rem]">
                  {languageState === "kr" ? (
                    <span className="drop-shadow-[2px_2px_3px_rgba(255,255,255,1)]">
                      현장 작업자 또는 오퍼레이터들이 프로그램을 손쉽게 조작할 수 있도록{" "}
                      <strong className="font-PretendardBL">
                        사용자 중심의 간편한 화면 제어 시스템
                      </strong>
                      을 제공합니다.
                    </span>
                  ) : (
                    <span className="drop-shadow-[2px_2px_3px_rgba(255,255,255,1)]">
                      Offers
                      <strong className="font-PretendardBL">
                        {" "}
                        a user-friendly control system{" "}
                      </strong>
                      designed for field workers and operators to easily manage the program{" "}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </section>
      {/* 사이즈 수동이 아닌 자동으로 조절되게 차후 수정필요 */}
      <section
        className={`relative ${
          window.innerWidth < 768 ? "bg-ControllerM" : "bg-Controller"
        } bg-cover bg-bottom bg-no-repeat w-full min-h-full h-full aspect-[504/960] md:aspect-[16/9] pt-[6vw] md:pt-[6.5vw]`}
      >
        <div className="w-full flex justify-center">
          <span className="text-[6.75vw] md:text-[3.374vw] w-fit text-center relative bottom-[2vw]">
            Using a <strong className="font-PretendardBL">SyncRo</strong> controller
          </span>
        </div>
      </section>
      {isLoading && <LoadingScreen />}
    </main>
  );
};
export default Product;

export const Section = ({ className, children }) => {
  return (
    <section className={`w-full h-fit `.concat(className || "")}>
      <div
        className={`relative grid grid-cols-[4.99%_minmax(0%,90%)_4.99%] md:grid-cols-[9.99%_minmax(0%,80%)_9.99%] 4xl:grid-cols-[12%_76%_12%] w-full min-w-fit mx-auto bg-inherit pt-[13vw] md:pt-[7.5rem]`}
      >
        <div className="w-full flex" />
        {children}
        <div className="w-full flex" />
      </div>
    </section>
  );
};
// pc
// 16:9=320:180
// 16:9=360:202.5
// 16:9=768:432
// 16:9=1200:675
// 16:9=1920:1080
// 16:9=2560:1440

// mobile
// 9:19.5=167:320
// 9:19.5=188:360
// 9:19.5=403:768
// 9:19.5=630:1200
// 9:19.5=1000:1920
// 9:19.5=1280:2560

// {
//   bg: `bg-No_Pg`,
//   title: `이제는 코딩이 필요 없는 시대\n작업자의 행동 실시간 생성.`,
//   description: () => (
//     <span>
//       작업자의 작업 경로를 스캔하는 컨트롤러
//       <br />
//       하나 만으로
//       <strong className="font-PretendardBL">프로그래밍 작업 필요 없이</strong>
//       <br />
//       로봇 로직을 실시간으로 생성합니다.
//     </span>
//   ),
// },
// {
//   bg: `bg-monitor`,
//   title: `쉬운 GUI 관리자 화면으로\n작업 관리를 한 눈에.`,
//   description: () => (
//     <span>
//       현장 작업자 또는 오퍼레이터들이
//       <br />
//       로그램을 손쉽게 조작할 수 있도록
//       <br />
//       <strong className="font-PretendardBL">사용자 중심의 간편한 화면 제어 시스템을</strong>
//       <br />
//       제공합니다.
//     </span>
//   ),
// },
