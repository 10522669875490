import { Suspense } from "react";
import HorizontalScroll from "../components/HorizontalScroll";
import SkeletonLoader from "./SkeletonLoader";
const LogoSlider = ({ logosArr }) => {
  return (
    <div className="">
      <div className="relative grid grid-rows-[3fr_2fr_3fr] items-center py-[2.5rem] md:py-[3rem] xl:py-[3.5rem] w-full h-[16rem] md:h-[20rem] xl:h-[25rem] bg-white">
        <div className="gradientToTop absolute bottom-0 left-0 w-full h-[5%] z-[1]" />
        <div className="gradientToBottom absolute top-0 left-0 w-full h-[5%] z-[1]" />
        <HorizontalScroll direction={"forward"} stop={false}>
          {logosArr.map((item, index) => {
            if (index <= logosArr.length / 2) {
              return (
                <li
                  key={index}
                  draggable={false}
                  className={`${
                    item.name === "SEMS"
                      ? "p-[1rem_1rem] w-fit max-w-[23%]"
                      : item.name === "성원 교역"
                      ? "p-[1rem_1rem] w-fit max-w-[23%]"
                      : "p-2 md:p-3"
                  } min-w-[1rem] w-fit h-full relative rounded-lg`}
                >
                  <Suspense fallback={<SkeletonLoader />}>
                    <img
                      src={item.uri}
                      alt={item.name}
                      className={`flex w-auto h-full mx-0 object-contain select-none`}
                      draggable={false}
                    />
                  </Suspense>
                </li>
              );
            }
          })}
        </HorizontalScroll>
        <div />
        <HorizontalScroll direction={"backward"} stop={false}>
          {logosArr.map((item, index) => {
            if (index > logosArr.length / 2) {
              return (
                <li
                  key={index}
                  draggable={false}
                  className={`${
                    item.name === "SEMS"
                      ? "p-[1rem_1rem] w-fit max-w-[23%]"
                      : item.name === "성원 교역"
                      ? "p-[1rem_1rem] w-fit max-w-[23%]"
                      : "p-2 md:p-3"
                  } min-w-[1rem] md:min-w-[5rem] w-fit h-full relative rounded-lg`}
                >
                  <Suspense fallback={<SkeletonLoader />}>
                    <img
                      src={item.uri}
                      alt={item.name}
                      className={`flex w-auto h-full mx-0 object-contain select-none`}
                      draggable={false}
                    />
                  </Suspense>
                </li>
              );
            }
          })}
        </HorizontalScroll>
      </div>
    </div>
  );
};

export default LogoSlider;
