import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useStateStore = create(
  persist(
    (set) => ({
      languageState: undefined,
      setLanguageState: (language) => set({ languageState: language }),
    }),
    { name: "language-state" }
  )
);

export const useIsLoadingStore = create(
  // persist(
  (set) => ({
    isLoading: true,
    setIsLoading: (loading) => set({ isLoading: loading }),
  }),
  { name: "is-loading" }
  // )
);
