import { useLoaderData, useNavigate } from "react-router-dom";

const icons = ["/images/fi_10478244.png", "/images/fi_493881.png", "/images/fi_1307909.png"];

const Recruit = () => {
  const loaderData = useLoaderData();
  const recruitData = loaderData?.recruitData;
  const etcData = loaderData?.etcData;

  return (
    <div className="font-PretendardM grid grid-cols-[5%_90%_5%] md:grid-cols-[17%_66%_17%] bg-[#151515]">
      <div
        onWheel={(e) => {
          if (e.deltaY < 0 && window.scrollY === 0) {
            e.target.nextSibling.scrollTop += e.deltaY;
          } else if (
            e.deltaY >= 0 &&
            window.scrollY === window.document.body.scrollHeight - window.innerHeight
          ) {
            e.target.nextSibling.scrollTop += e.deltaY;
          }
        }}
      />
      <div className="flex flex-col p-20 mt-[5rem] w-full min-w-fit h-screen items-center">
        <div className="flex flex-col gap-2">
          <span className="flex text-white text-3xl">채용중인 포지션</span>
          <span className="flex text-white">description</span>
          <ul className="flex w-fit h-1/2 gap-4 min-w-fit">
            {recruitData?.map((recruit, index) => {
              return (
                <RecruitCard
                  key={index}
                  title={recruit.title}
                  description={recruit.description}
                  index={recruit.index}
                />
              );
            })}
          </ul>
        </div>
        <div className="flex flex-col gap-2">
          <span className="flex text-white text-lg">이런 분들을 원해요!</span>
          <ul className="flex gap-4 min-w-fit">
            {etcData?.map((etc, index) => {
              return (
                <EtcCard
                  key={index}
                  title={etc.title}
                  description={etc.description}
                  index={etc.index}
                />
              );
            })}
          </ul>
        </div>
      </div>
      <div
        onWheel={(e) => {
          if (e.deltaY < 0 && window.scrollY === 0) {
            e.target.nextSibling.scrollTop += e.deltaY;
          } else if (
            e.deltaY >= 0 &&
            window.scrollY === window.document.body.scrollHeight - window.innerHeight
          ) {
            e.target.nextSibling.scrollTop += e.deltaY;
          }
        }}
      />
    </div>
  );
};

export default Recruit;

const RecruitCard = ({ title, description, index }) => {
  const navigate = useNavigate();
  return (
    <li
      className="flex flex-col w-[18rem] h-[22rem] rounded-lg cursor-pointer hover:ring-2 ring-white hover:scale-[1.01] transition-transform duration-200"
      onClick={() => {
        navigate(`/recruit/pages?page=${index}`);
      }}
    >
      <picture className="flex bg-gradient-to-br from-white via-white to-gray-500 w-full min-w-fit h-full rounded-t-lg">
        <span className="m-auto text-3xl text-gray-500 font-bold">Image</span>
      </picture>
      <div className="flex flex-col w-full min-h-fit p-4 rounded-b-lg bg-[#23263A]">
        <span className="text-white text-xl">모집 직군</span>
        <span className="text-white text-xs mt-2">서울 | 경력(0-00년) | 상시모집</span>
        <button className="p-1 ml-auto bg-gradient-to-br from-[#8941FF] from-30% to-[#484BFF] rounded-lg w-[4.5rem]">
          <span className="text-white text-xs">지원하기</span>
        </button>
      </div>
    </li>
  );
};

const EtcCard = ({ title, description, index }) => {
  return (
    <li className="flex w-[18rem] h-full px-6 py-4 gap-6 bg-[#85919E] rounded-lg cursor-pointer hover:ring-2 ring-white hover:scale-[1.01] transition-transform duration-200">
      <picture className="flex min-w-[2.5rem] h-[2.5rem] rounded-lg relative">
        <img src={`${icons[index - 1]}`} alt="" className="w-full h-full object-cover rounded-lg" />
      </picture>
      <div className="flex flex-col whitespace-pre-wrap">
        <span className="text-white text-xl">{title}</span>
        <span className="text-white text-xs mt-2">{description}</span>
      </div>
    </li>
  );
};
