import React, { useState, useEffect, useRef } from "react";
import SkeletonLoader from "./SkeletonLoader";
import { useStateStore } from "../utils/zustandStore";

const ImageScroller = ({ images, width: w, height: h }) => {
  const imagesArr = images;
  const imageRef = useRef(null);
  const [imageIndex, setImageIndex] = useState(0);
  const { languageState } = useStateStore();
  // useEffect(() => {}, [images.length !== 0]);

  useEffect(() => {
    window.addEventListener("resize", handleScroll);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    try {
      const canvas = imageRef.current;
      const ctx = canvas.getContext("2d");
      const image = new Image();
      image.src = imagesArr[imageIndex];
      image.onload = () => {
        ctx.drawImage(image, 0, 0, w || 400, h || 400);
      };
    } catch (error) {
      console.log(error);
      return;
    }
  }, [imageIndex, imagesArr, w, h]);

  const handleScroll = () => {
    if (imagesArr.length === 0) return;
    const targetElement =
      imageRef?.current?.parentNode?.parentNode?.parentNode?.getBoundingClientRect();
    const gap =
      (targetElement?.height - window.innerHeight === 0
        ? targetElement?.height
        : targetElement?.height - window.innerHeight) / imagesArr.length;
    const currentPage = Math.floor(
      (window.scrollY - (window.scrollY + targetElement?.top)) / gap +
        (window.innerWidth < 768 ? 40 : 0)
    );
    if (currentPage < 0) return setImageIndex(0);
    if (currentPage > imagesArr.length) return setImageIndex(imagesArr.length);
    setImageIndex(currentPage);
  };
  return (
    <div className={`relative w-full h-[120%] md:h-[200vh] pt-[6.25em] md:pt-[7.5em]`}>
      <div
        className={`md:sticky top-[5%] md:top-[calc(50%_-_20vw)] flex flex-col md:flex-row justify-center items-center md:items-start gap-y-[5vw] md:gap-x-[5vw] mt-[10vw] md:mt-0`}
      >
        <div className="flex flex-col gap-[1.75vw] break-keep w-full h-fit md:translate-x-[3vw]">
          <span className="text-[2.75vw] md:text-[1.375vw] font-PretendardBL uppercase text-[#717071]">
            function
          </span>
          <span
            className={`text-[6vw] ${
              languageState === "kr" ? "md:text-[3vw]" : "md:text-[2.875vw]"
            } mt-[5vw] md:mt-[1vw] font-PretendardBL leading-[8vw] md:leading-[4vw] whitespace-pre-wrap`}
          >
            {languageState === "kr"
              ? `행동 기록 실시간\n자동화 로봇`
              : "Robot Automation Based on Motion Logging"}
          </span>
          {languageState === "kr" ? (
            <>
              <span className="text-[3.75vw] md:text-[1.875vw] md:w-[32vw] mt-[1vw] md:mt-0">
                작업자의 움직임을 실시간으로 기록하고 행동 데이터를 자동화합니다. <br />
                <strong className="font-PretendardBL">1분 만에 작업자의 행동 데이터를 설정</strong>
                하고, 최적의 작업 경로 학습 및 생성 기술을 통해 쾌적한 작업 환경으로 전환합니다.
              </span>
            </>
          ) : (
            <>
              <span className="text-[3.75vw] md:text-[1.875vw] mt-[1vw] leading-[6vw] md:leading-[3vw]">
                Real-time recording of worker movements with automated behavior data processing.{" "}
                <strong className="font-PretendardBL">
                  Configure worker behavior data in just one minute{" "}
                </strong>
                and transform the work environment through optimal path learning and generation
                technology.
              </span>
            </>
          )}
        </div>
        <div className="aspect-[1/1] w-full min-w-[40%] bg-slate-400 rounded-[1em] shadow-[3px_3px_10px_rgba(50,50,50,0.8)] overflow-hidden flex">
          {images.length !== 0 ? (
            <canvas
              ref={imageRef}
              width={w || 400}
              height={h || 400}
              style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px" }}
            />
          ) : (
            <SkeletonLoader size={"full"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageScroller;

// const distance =
// imageRef.current.getBoundingClientRect().top + window.scrollY - window.innerHeight * 0.3;
//   if (deltaY < 0) {
//     console.log("scroll up");
//     if (!scrollLock) {
//       if (top < window.innerHeight * 0.3 && currentIndex > 0) {
//         scrollLock = true;
//         return;
//       }
//     } else {
//       if (currentIndex === 0) {
//         setIndex(0);
//         scrollLock = false;
//         return;
//       } else if (currentIndex > 0) {
//         window.scrollTo(0, distance);
//         setIndex((current) => current - 1);
//         currentIndex -= 1;
//         return;
//       }
//     }
//   } else if (deltaY > 0) {
//     console.log("scroll down");
//     if (!scrollLock) {
//       if (bottom > window.innerHeight * 0.3 && currentIndex < images.length - 1) {
//         scrollLock = true;
//         return;
//       }
//     } else {
//       if (currentIndex === images.length - 1) {
//         setIndex(images.length - 1);
//         scrollLock = false;
//         return;
//       } else if (currentIndex < images.length - 1) {
//         window.scrollTo(0, distance);
//         setIndex((current) => current + 1);
//         currentIndex += 1;
//         return;
//       }
//     }
//   }
// };

// if (!scrollLock) {
//   if (top < window.innerHeight * 0.3 && bottom > window.innerHeight * 0.5) {
//     scrollLock = true;
//     scrollY = window.scrollY;
//     return;
//   } else {
//     scrollLock = false;
//     return;
//   }
// } else {
//   if (scrollY > window.scrollY) {
//     console.log("scroll up");
//     if (currentIndex > 0) {
//       window.scrollTo(0, scrollY);
//       setIndex((current) => current - 1);
//       currentIndex -= 1;
//       return;
//     } else if (currentIndex === 0) {
//       setIndex(0);
//       // scrollLock = false;
//       return;
//     }
//   } else if (scrollY < window.scrollY) {
//     console.log("scroll down");
//     if (currentIndex < images.length - 1) {
//       window.scrollTo(0, scrollY);
//       setIndex((current) => current + 1);
//       currentIndex += 1;
//       return;
//     } else if (currentIndex === images.length - 1) {
//       setIndex(images.length - 1);
//       scrollLock = false;
//       return;
//     }
//   } else {
//     window.scrollTo(0, scrollY);
//     return;
//   }
// }

// if (top < 0 && bottom > window.innerHeight) {
//   if (recentScrollY > window.scrollY) {
//     console.log("scroll up");
//     recentScrollY = window.scrollY;
//     if (currentIndex > 0) {
//       setIndex(currentPage);
//       currentIndex = currentPage;
//       return;
//     } else if (currentIndex === 0) {
//       setIndex(currentPage);
//       return;
//     }
//   } else if (recentScrollY < window.scrollY) {
//     console.log("scroll down");
//     recentScrollY = window.scrollY;
//     if (currentIndex < images.length - 1) {
//       setIndex(currentPage);
//       currentIndex = currentPage;
//       return;
//     } else if (currentIndex === images.length - 1) {
//       setIndex(currentPage);
//       return;
//     }
//   } else {
//     return;
//   }
// }
