import { Suspense } from "react";
import Carousel from "../components/Carousel";
import { useStateStore } from "../utils/zustandStore";
import SkeletonLoader from "../components/SkeletonLoader";

const Contents = () => {
  const videosUri = [
    {
      uri: `${process.env.PUBLIC_URL}/videos/teaching-1.mp4`,
      mimeType: "video/mp4",
      title: "teaching-1",
    },
    {
      uri: `${process.env.PUBLIC_URL}/videos/teaching-2.mp4`,
      mimeType: "video/mp4",
      title: "teaching-2",
    },
    {
      uri: `${process.env.PUBLIC_URL}/videos/teaching-3.mp4`,
      mimeType: "video/mp4",
      title: "teaching-3",
    },
    // { uri: `${process.env.PUBLIC_URL}/images/Facebook_Logo_Secondary.png`, mimeType: "image/png" },
  ];
  const { languageState } = useStateStore();

  return (
    <div
      className={`flex flex-col w-full h-full max-h-screen pt-[5rem] md:pt-[10rem] bg-[#151515] relative font-PretendardM text-white overflow-hidden`}
    >
      <span className="fontTitleM font-PretendardB text-center self-center mt-[5rem]">
        {`${languageState === "kr" ? "히스토리" : "Tech History"}`}
      </span>
      <div
        className={`customSlider w-full h-full flex bg-[#151515] relative mt-[5rem] 1.5xs:mt-[6rem] md:mt-[7rem] xl:mt-[9rem] 4xl:mt-[11rem]`}
        // className={`customSlider w-full h-full flex bg-Line-Normal-Strong relative mt-[5rem] 1.5xs:mt-[6rem] md:mt-[7rem] xl:mt-[9rem] 4xl:mt-[11rem]`}
      >
        <Carousel
          isVideo={true}
          stop={true}
          direction={"forward"}
          className={`w-full h-full relative`}
          options={{
            slidesToShow: 3,
            arrows: true,
            draggable: true,
            lazyLoad: "anticipated",
          }}
        >
          {videosUri.map((item, index) => {
            return (
              <div key={index} className="">
                <div className="flex w-full h-full relative overflow-hidden">
                  {item.mimeType.includes("video") ? (
                    <Suspense fallback={<SkeletonLoader size={"half"} />}>
                      <div className="absolute flex justify-center items-center w-full h-full top-0 left-0 bg-transparent bg-opacity-0 z-10">
                        <picture className="z-20">
                          <source srcSet={`${process.env.PUBLIC_URL}/images/play-button.svg`} />
                          <img
                            src={`${process.env.PUBLIC_URL}/images/play-button.svg`}
                            alt=""
                            draggable="false"
                            className="w-[2rem] 1.5xs:w-[3rem] md:w-[4.25rem] xl:w-[6.25rem] 4xl:w-[7rem] 5xl:w-[9rem] 8xl:w-[12rem] h-[2rem] 1.5xs:h-[3rem] md:h-[4.25rem] xl:h-[6.25rem] 4xl:h-[7rem] 5xl:h-[9rem] 8xl:h-[12rem] z-20 opacity-70 hover:opacity-100 cursor-pointer select-none"
                            onClick={() => {
                              makeVideoModal(item, index);
                            }}
                          />
                        </picture>
                      </div>
                      <video
                        id={`video-${index}`}
                        className="relative w-full h-full object-cover object-center rounded-lg overflow-hidden"
                        muted
                        playsInline
                        poster={`${process.env.PUBLIC_URL}/images/thumbnails/thumbnail-${index}.png`}
                      >
                        <source src={item.uri} type="video/mp4" />
                      </video>
                    </Suspense>
                  ) : item.mimeType.includes("image") ? (
                    <div className="absolute flex justify-center items-center w-full h-full top-0 left-0 bg-black bg-opacity-50 z-10">
                      <img
                        src={item.uri}
                        className="w-full h-full object-contain object-center"
                        alt=""
                        draggable="false"
                      />
                    </div>
                  ) : null}
                </div>
                <span className="fontDescL2 flex w-fit mx-auto mt-[1rem]">{item.title}</span>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Contents;

const makeVideoModal = (item, index) => {
  const modal = document.createElement("div");
  const modalOverlay = document.createElement("div");
  const modalContents = document.createElement("div");
  const video = document.createElement("video");
  const xMark = document.createElement("img");

  modal.className = "fixed top-0 left-0 w-full h-full z-30 flex justify-center items-center";

  modalOverlay.className = "absolute top-0 left-0 w-full h-full bg-black bg-opacity-80 z-30";
  modalOverlay.addEventListener("click", () => {
    document.body.removeChild(modal);
  });

  modalContents.className =
    "relative w-[75vw] h-[75vh] min-w-[12rem] min-h-[9rem]  rounded-lg object-cover object-center m-auto z-40";

  video.id = `modal-video-${index}`;
  video.className = "w-full h-full rounded-lg object-cover object-center";
  video.autoplay = true;
  video.loop = true;
  video.muted = true;
  video.playsInline = true;
  video.controls = true;
  video.innerHTML = `<source src="${item.uri}" />`;

  xMark.className =
    "absolute top-5 right-5 w-[2rem] h-[2rem] cursor-pointer z-40 mix-blend-difference";
  xMark.src = `${process.env.PUBLIC_URL}/images/x-mark.svg`;
  xMark.addEventListener("click", () => {
    document.body.removeChild(modal);
  });

  document.body.appendChild(modal);
  modal.appendChild(modalOverlay);
  modal.appendChild(modalContents);
  modalContents.appendChild(video);
  modalContents.appendChild(xMark);
  video.play();
};
