import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateStore } from "../utils/zustandStore";
import Footer from "./Footer";
import { isVisible } from "@testing-library/user-event/dist/utils";

const Header = () => {
  const navigatorLIsts = [
    { title: "Home", active: true, path: "/" },
    { title: "SyncRo", active: true, path: "/product" },
    // { title: "Contents", active:false,path: "/contents" },
    {
      title: "Recruit",
      active: false,
      path: "/recruit",
      link: "https://affine-robotics.notion.site/",
    },
    { title: "News", active: true, path: "/news?page=1" },
    { title: "Contact", active: true, path: "#contact" },
  ];
  const pathname = window.location.pathname;
  const { languageState, setLanguageState } = useStateStore();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [openNavigator, setOpenNavigator] = useState(false);
  const goToPage = (page) => {
    navigate(page);
  };

  let timeoutId = null;

  const handleOpenNavigator = () => {
    setOpenNavigator(!openNavigator);
    document.body.style.overflow = openNavigator ? "unset" : "hidden";

    if (openNavigator) {
      setIsVisible(true);
    }

    if (window.innerWidth > 768) {
      document.body.style.overflow = "unset";
      setOpenNavigator(false);
    } else {
      document.body.style.overflow = openNavigator ? "unset" : "hidden";
    }
  };

  const handleScroll = () => {
    if (window.scrollY === 0) {
      document.querySelector(".scrollToTop")?.classList.add("hidden");
      return setIsVisible(true);
    } else {
      document.querySelector(".scrollToTop")?.classList.remove("hidden");
    }
    setIsVisible(false);
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => setIsVisible(true), 1000);
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      handleScroll();
    });
    return () => {
      window?.removeEventListener("scroll", () => {
        handleScroll();
      });
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 768) {
        document.body.style.overflow = "unset";
        setOpenNavigator(false);
      }
    });
    return () => {
      window?.removeEventListener("resize", () => {
        if (window.innerWidth >= 768) {
          document.body.style.overflow = "unset";
          setOpenNavigator(false);
        }
      });
    };
  }, []);
  return (
    <header
      className={`flex items-center bg-[rgba(55,56,60,0.8);] fixed top-0 left-0 w-full z-20 backdrop-blur-[10px] transition-all ${
        isVisible ? "visible opacity-100 duration-500" : "invisible opacity-0 duration-300"
      }`}
    >
      <div className="w-[1.25rem] md:w-[2.5rem] xl:w-[5rem] xl:max-w-[5rem] 4xl:w-[15%] 4xl:max-w-full 8xl:w-[40%] h-full" />
      <div className={`flex justify-between w-full`}>
        <picture
          className="absolute block md:hidden w-[2rem] right-[5%] top-[30%] cursor-pointer"
          onClick={() => {
            document.body.style.overflow = "hidden";
            setOpenNavigator(true);
            setIsVisible(true);
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/images/hamburger.svg`} alt="" />
        </picture>
        <div
          className={`${
            openNavigator
              ? "-translate-x-[1.25rem] md:-translate-x-[2.5rem] xl:-translate-x-[5rem] 4xl:-translate-x-[15%] 8xl:-translate-x-[40%]"
              : "translate-x-[300vw]"
          } fixed w-full h-screen z-50 bg-Label-Normal transition-transform duration-300 flex flex-col overflow-y-scroll scrollbar-hide`}
        >
          <div className="flex justify-between px-[1.25rem] py-[1rem] bg-Label-Assistive">
            <picture
              className="relative min-w-[50px] max-w-[3.5rem] 1.5xs:max-w-[4.375rem] h-[100%] select-none cursor-pointer"
              onClick={() => {
                scrollToTop();
                document.body.style.overflow = "unset";
                setOpenNavigator(false);
                goToPage("/");
              }}
            >
              <img
                // src="/images/logo.png"
                src="/images/logo2.svg"
                alt="Affine Robotics"
                // className={`max-w-full max-h-full w-full h-full ${path === "/" ? "invert" : "invert"}`}
                className={`absolute top-0 left-0 w-full h-full object-contain object-center ${
                  pathname !== "/" ? "invert" : ""
                }`}
              />
            </picture>
            <picture
              className="w-fit relative select-none cursor-pointer"
              onClick={() => {
                document.body.style.overflow = "unset";
                setOpenNavigator(false);
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/x-mark.svg`}
                className="w-[1.5rem] h-[1.5rem]"
                alt=""
              />
            </picture>
          </div>
          {/* <div className="w-full h-[1px] bg-gray-400" /> */}
          <ul className="flex flex-col gap-[2rem] my-[3.375rem] px-[1.25rem]">
            <NavigatorLIsts
              array={navigatorLIsts}
              openNavigator={openNavigator}
              handler={handleOpenNavigator}
              setOpenNavigator={setOpenNavigator}
            />
          </ul>
          <div className="mt-auto">
            <Footer />
          </div>
        </div>
        <div className={`w-full flex justify-between items-center h-fit mx-auto py-[1rem]`}>
          <picture
            className="min-w-[2.5rem] max-w-[3.5rem] 1.5xs:max-w-[4.375rem] xl:max-w-[5.25rem] 4xl:max-w-[7rem] 5xl:max-w-[7.875rem] h-full relative select-none cursor-pointer"
            onClick={() => {
              scrollToTop();
              goToPage("/");
            }}
          >
            <img
              // src="/images/logo.png"
              src="/images/logo2.svg"
              alt="Affine Robotics"
              // className={`max-w-full max-h-full w-full h-full ${path === "/" ? "invert" : "invert"}`}
              className={`max-w-full max-h-full w-full h-full`}
            />
          </picture>
          <ul
            className={`hidden md:flex gap-[1rem] 2xs:gap-[1.2rem] xs:gap-[1.4rem] sm:gap-[1.6rem] md:gap-[1.8rem] lg:gap-[2rem] cursor-pointer select-none z-20`}
          >
            <NavigatorLIsts
              array={navigatorLIsts}
              openNavigator={openNavigator}
              handler={handleOpenNavigator}
              setOpenNavigator={setOpenNavigator}
            />
          </ul>
        </div>
      </div>
      <div className="w-[1.25rem] md:w-[2.5rem] xl:w-[5rem] xl:max-w-[5rem] 4xl:w-[15%] 4xl:max-w-full 8xl:w-[40%] h-full" />
    </header>
  );
};

export default Header;

const NavigatorLIsts = ({ array, openNavigator, handler, setOpenNavigator }) => {
  const { languageState } = useStateStore();
  const navigate = useNavigate();

  return (
    <>
      {array.map((list, index) => (
        <li
          key={index}
          className={`leading-[3rem] ${
            list.path.split("?")[0] === window.location.pathname ||
            window.location.pathname === list.path
              ? "font-PretendardB text-[#ffffff]"
              : "font-PretendardM text-[rgba(rgba(244,244,245,1))] invert"
          }`}
          onClick={() => {
            if (list.title === "Home") {
              window.scrollTo({ top: 0, behavior: "instant" });
              handler();
              navigate("/");
              return;
            }
            if (list.title === "SyncRo") {
              window.scrollTo({ top: 0, behavior: "instant" });
              handler();
              navigate("/product");
              return;
            }
            if (list.title === "News") {
              window.scrollTo({ top: 0, behavior: "instant" });
              handler();
              navigate("/news?page=1");
              return;
            }

            if (list.title === "Contact") {
              setOpenNavigator(false);
              document.body.style.overflow = "unset";
              const contact = document.getElementById("contact");
              if (contact) {
                window.scrollTo({
                  top:
                    document.getElementById("contact")?.getBoundingClientRect().bottom +
                    window.scrollY,
                  behavior: "smooth",
                });
              }
              navigate("/#contact");
              return;
            }
            if (list.link) return window.open(list.link, "_blank");
            list.active
              ? navigate(list.path)
              : alert(`${languageState === "kr" ? "준비 중입니다." : "Coming Soon..."}`);
          }}
        >
          <span className={`cursor-pointer ${!openNavigator ? "fontTitleES" : "fontTitleM"}`}>
            {list.title}
          </span>
        </li>
      ))}
    </>
  );
};
