import { useEffect } from "react";
import { useStateStore } from "../utils/zustandStore";
const Footer = () => {
  const { languageState, setLanguageState } = useStateStore();

  const getBrowserLanguage = () => {
    const language = navigator.language || navigator.userLanguage;
    return language;
  };

  useEffect(() => {
    let browserLanguage = languageState ? languageState : getBrowserLanguage();
    switch (browserLanguage) {
      case "ko":
        setLanguageState("kr");
        break;
      case "ko-KR":
        setLanguageState("kr");
        break;
      case "en":
        setLanguageState("en");
        break;
      case "en-US":
        setLanguageState("en");
        break;
      default:
        setLanguageState("en");
        break;
    }
  }, []);
  return (
    <footer className="font-PretendardM w-full bg-[#151515] flex flex-col sm:flex-row gap-[2.5rem] justify-center py-[2.5rem] px-[1.25rem] select-none">
      <span className="fontExtraS w-fit text-center self-center text-Interaction-Inactive text-[.875rem]">
        Copyright &copy; 2024 AffineRobotics. All rights reserved.
      </span>
      <div className="flex items-center h-full gap-1 fontExtraL text-white cursor-pointer self-center">
        <span
          className={`${languageState === "en" ? "text-[#ffffff]" : "text-Interaction-Inactive"}`}
          onClick={() => {
            setLanguageState("en");
          }}
        >
          English
        </span>
        <span>{" / "}</span>
        <span
          className={`${languageState === "kr" ? "text-[#ffffff]" : "text-Interaction-Inactive"}`}
          onClick={() => {
            setLanguageState("kr");
          }}
        >
          한국어
        </span>
      </div>
      <div className="flex items-center gap-5 text-[.75rem] text-white cursor-pointer self-center">
        <picture
          className={`text-[#ffffff] w-[1.5rem] md:w-[2rem] h-[1.5rem] md:h-[2rem]`}
          onClick={() => {
            alert(`${languageState === "kr" ? "준비 중입니다." : "Coming Soon..."}`);
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/Facebook_Logo_Secondary.png`}
            alt=""
            className="w-full h-full object-cover"
          />
        </picture>
        <picture
          className={`text-[#ffffff] w-[1.764140625rem] md:w-[2.3521875rem] h-[1.5rem] md:h-[2rem]`}
          onClick={() => {
            window.open("https://kr.linkedin.com/company/portal301", "_blank");
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/LI-In-Bug.png`}
            alt=""
            className="invert brightness-0 w-full h-full object-cover"
          />
        </picture>
      </div>
    </footer>
  );
};

export default Footer;
