import { useEffect, useRef } from "react";
import { useIsLoadingStore } from "../utils/zustandStore";

const LoadingScreen = () => {
  const logoRef = useRef(null);
  const screenRef = useRef(null);
  const { setIsLoading } = useIsLoadingStore();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    splashScreenHandler();
  }, []);

  const splashScreenHandler = async () => {
    const logo = logoRef.current;
    const screen = screenRef.current;

    logo.style.opacity = 0;
    await new Promise((resolve) => setTimeout(resolve, 500));
    logo.style.opacity = 1;
    logo.style.transition = "opacity 3s";
    await new Promise((resolve) => setTimeout(resolve, 3000));
    screen.style.transition = "opacity 0.5s";
    screen.style.opacity = 0;
    logo.style.transition = "opacity 0.5s";
    logo.style.opacity = 0;
    await new Promise((resolve) => setTimeout(resolve, 500));
    document.body.style.overflow = "auto";
    setIsLoading(false);
  };

  return (
    <div
      id="splash-screen"
      className="fixed top-0 left-0 w-full h-[100vh] bg-black z-[99] flex justify-center items-center"
      ref={screenRef}
    >
      <picture
        id="splash-logo"
        className="relative -translate-y-[5rem] md:translate-y-0 w-[50vw] md:w-[calc(30%-10vw)] text-white"
        ref={logoRef}
      >
        <img src="/images/logo2.svg" alt="" className="w-full h-full object-cover object-center" />
      </picture>
    </div>
  );
};

export default LoadingScreen;
