import { useEffect, useRef, useState } from "react";
import { Section } from "./Product";

const Test = () => {
  const sliderRef = useRef();
  const testImages = {
    images: Array.from(
      { length: 50 },
      (_, i) => `${process.env.PUBLIC_URL}/images/test/u${i + 1}.jpg`
    ),
  };
  useEffect(() => {
    // let isDragging = false;
    let imgMono = document.getElementById("imgMono");
    imgMono.style.width = "50%";
    let imgColor = document.getElementById("imgColor");
    imgColor.style.width = "50%";
    const handleMouseMove = (e) => {
      let imageWidth = 50;
      // if (!isDragging) return;
      const slider = sliderRef.current;
      const { left, right, width } = slider.parentNode.getBoundingClientRect();
      const percent = ((e.clientX - left) / width) * 100;

      if (percent > 0 && percent < 100) {
        console.log("Mouse Move");

        imageWidth = percent;
        if (e.clientX - left < 0) {
          slider.style.left = "0%";
          imageWidth = 0;
        } else if (e.clientX - left > right - left) {
          slider.style.left = "100%";
          imageWidth = 100;
        } else {
          slider.style.left = `${percent}%`;
          imageWidth = Math.floor(percent);
          console.log(imageWidth);
          imgMono.style.width = `${imageWidth}%`;
          imgColor.style.width = `${100 - imageWidth}%`;
        }
      }
    };

    const handleMouseDown = () => {
      // isDragging = true;
      // console.log(isDragging);
      console.log("Mouse Down");
    };
    const handleMouseUp = () => {
      // isDragging = false;
      // console.log(isDragging);
      console.log("Mouse Up");
    };

    sliderRef.current.parentNode.addEventListener("mousemove", handleMouseMove);
    // sliderRef.current.parentNode.addEventListener("mousedown", handleMouseDown);
    // sliderRef.current.parentNode.addEventListener("mouseup", handleMouseUp);

    return () => {
      sliderRef.current.parentNode?.removeEventListener("mousemove", handleMouseMove);
      sliderRef.current.parentNode?.removeEventListener("mousedown", handleMouseDown);
      sliderRef.current.parentNode?.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);
  return (
    <Section>
      <div className="relative bg-black rounded-xl aspect-[10/5.6] gap-[3rem] md:gap-[5rem] w-full p-[2.1875rem_4.375rem] flex flex-col md:flex-row justify-between">
        <div className="text-white flex flex-col gap-[2rem]">
          <span className="fontTitleM font-PretendardB">SyncRo.</span>
          <span className="fontDescS">처음부터 정확하게, 품질은 균일하게</span>
        </div>
        <div className="relative aspect-[1/1] w-fit min-w-[10rem] bg-[#717071] rounded-xl overflow-hidden">
          <picture className="w-full h-full flex">
            <img
              src="https://staticsrc.s3.ap-northeast-2.amazonaws.com/images/SyncRo.web/syncro_monochrom.png"
              alt=""
              className="h-full object-cover object-left"
              draggable={false}
              id="imgMono"
            />
            <img
              src="https://staticsrc.s3.ap-northeast-2.amazonaws.com/images/SyncRo.web/SyncRo.png"
              alt=""
              className="h-full object-cover object-right"
              draggable={false}
              id="imgColor"
            />
          </picture>
          <div
            ref={sliderRef}
            id="SliderView"
            className="absolute flex items-center justify-center top-0 left-[50%] h-full w-[.25rem] bg-transparent z-[20] overflow-visible select-none cursor-pointer"
            draggable={false}
          >
            {/* <div
              className="absolute top-[calc(50%_-_1.5rem)] w-[3rem] h-[3rem] rounded-full bg-[rgba(74,160,216,0.75)] border-[3px] z-[30]"
              draggable={false}
            /> */}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Test;
