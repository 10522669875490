const ContentsBlock = ({ className, style, children }) => {
  return (
    <div
      className={`relative grid grid-cols-[5%_90%_5%] md:grid-cols-[17%_66%_17%] w-full min-w-fit mx-auto bg-[#151515] pt-[6.25rem] md:pt-[7.5rem] xl:pt-[10rem] `.concat(
        className
      )}
      style={style || null}
    >
      {/* <div className={`flex w-full min-h-fit relative mx-auto 4xl:mt-[10rem]`}> */}
      <div />
      <div>{children}</div>
      <div />
    </div>
  );
};

export default ContentsBlock;
