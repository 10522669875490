import { Suspense, useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import SkeletonLoader from "../components/SkeletonLoader";

const NEWS = () => {
  const loaderData = useLoaderData();
  const newsData = loaderData?.newsData;
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const path = parseInt(params.get("page"));
  const navigate = useNavigate();
  const show = 3;
  const [pages, setPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  useEffect(() => {
    setPages(Math.ceil(newsData?.length / show));
    return () => {
      setPages(null);
    };
  }, [newsData]);
  useEffect(() => {
    setCurrentPage(path || 1);
    return () => {
      setCurrentPage(null);
    };
  }, [path]);
  return (
    <div className="font-PretendardM grid grid-cols-[5%_90%_5%] md:grid-cols-[17%_66%_17%] bg-[#151515]">
      <div
        onWheel={(e) => {
          if (e.deltaY < 0 && window.scrollY === 0) {
            e.target.nextSibling.scrollTop += e.deltaY;
          } else if (
            e.deltaY >= 0 &&
            window.scrollY === window.document.body.scrollHeight - window.innerHeight
          ) {
            e.target.nextSibling.scrollTop += e.deltaY;
          }
        }}
      />
      <div className="flex flex-col w-full h-screen py-[5rem] px-[.625rem] gap-[2rem] overflow-scroll scrollbar-hide">
        <div className="flex flex-col w-full gap-[.5rem] mt-[2.5rem]">
          <span className="text-white fontTitleM">관련 뉴스 보기</span>
          <span className="text-white fontDescM">{`description`}</span>
        </div>
        <ul className="flex flex-wrap gap-[2rem]">
          {newsData?.slice((currentPage - 1) * show, currentPage * show).map((news, index) => {
            return (
              <NewsCard
                key={index}
                title={news.title}
                thumbnail={news.thumbnail}
                description={news.description}
                categories={news.categories}
                date={news.date}
                link={news.link}
                index={news.index}
              />
            );
          })}
        </ul>
        <div className="flex gap-2 mt-10 mx-auto fontExtraS">
          <button
            className="text-white"
            onClick={() => {
              if (currentPage === 1) return;
              navigate(`/news?page=${currentPage - 1}`);
            }}
          >
            {"<"}
          </button>
          {Array.from({ length: pages }, (_, i) => i + 1).map((page) => {
            return (
              <button
                key={page}
                className={`text-Interaction-Inactive hover:text-white ${
                  currentPage === page ? "text-white" : ""
                }`}
                onClick={() => {
                  navigate(`/news?page=${page}`);
                }}
              >
                {page}
              </button>
            );
          })}
          <button
            className="text-white"
            onClick={() => {
              if (currentPage === pages) return;
              navigate(`/news?page=${currentPage + 1}`);
            }}
          >
            {">"}
          </button>
        </div>
      </div>
      <div
        onWheel={(e) => {
          if (e.deltaY < 0 && window.scrollY === 0) {
            e.target.previousSibling.scrollTop += e.deltaY;
          } else if (
            e.deltaY >= 0 &&
            window.scrollY === window.document.body.scrollHeight - window.innerHeight
          ) {
            e.target.previousSibling.scrollTop += e.deltaY;
          }
        }}
      />
    </div>
  );
};
export default NEWS;

const NewsCard = ({ title, thumbnail, description, categories, date, link, index }) => {
  const navigate = useNavigate();
  return (
    <Suspense fallback={<SkeletonLoader size={"full"} />}>
      <li
        className="w-full grid grid-rows-[3fr_2fr] md:grid-cols-[1fr_3fr] md:grid-rows-none rounded-lg cursor-pointer hover:ring-2 ring-white hover:scale-[1.01] transition-transform duration-200"
        onClick={() => {
          link ? window.open(link, "_blank") : navigate(`/news/pages?page=${index}`);
        }}
      >
        <picture className="flex w-full bg-gradient-to-br from-white via-white to-gray-500 min-w-fit h-full rounded-t-lg md:rounded-tr-none md:rounded-l-lg overflow-hidden">
          {thumbnail ? (
            <img
              src={thumbnail}
              alt={title}
              className="w-full h-full object-cover object-center aspect-[4/3]"
            />
          ) : (
            <span className="m-auto text-3xl text-gray-500 font-bold aspect-[4.3]">Image</span>
          )}
        </picture>
        <div className="flex flex-col justify-between gap-[1rem] md:gap-[4rem] w-full h-full p-4 rounded-b-lg md:rounded-bl-none md:rounded-r-lg bg-[#23263A]">
          <div>
            <span className="text-white fontTitleES">{title}</span>
            {/* <span className="text-white mt-4">{description}</span> */}
          </div>
          <div className="flex flex-col gap-[.5rem]">
            {categories.map((category, index) => {
              return (
                <div
                  key={index}
                  className="w-fit bg-[rgba(255,255,255,0.1)] p-[.25rem_.5rem] md:p-[.5rem_.75rem] rounded-lg"
                >
                  <span className="text-white fontExtraL">{`#${category}`}</span>
                </div>
              );
            })}
            <span className="text-white fontExtraL">{date}</span>
          </div>
        </div>
      </li>
    </Suspense>
  );
};
