import React, { useRef, useEffect } from "react";

/**
 *
 * @param {Object} props
 * @param {String} props.direction - The direction of the scroll, either "forward" or "backward"
 * @param {Boolean} props.isVideo - The type of content, either video or image
 * @param {String} props.className - The class name of the component, if any
 * @param {ReactNode} props.children - The children of the component, if any
 * @returns
 */
const HorizontalScroll = ({ stop, dragable, direction, isVideo, className, children }) => {
  const scrollRef = useRef();
  let requestId;
  let startX = 0;
  let isMouseDown = false; // Flag to track mouse down state

  useEffect(() => {
    try {
      let scrollPos = 0;
      const loop = () => {
        // console.log("scrollPos: ", scrollPos);
        // console.log("scrollWidth: ", scrollRef?.current.scrollWidth / 5 + (isVideo ? 0 : 8));
        // console.log("scrollLeft: ", scrollRef.current.scrollLeft);
        if (stop) return;
        if (direction === "forward") {
          scrollPos += 1;
          if (scrollPos >= scrollRef?.current?.scrollWidth / 5 + (isVideo ? 0 : 8)) {
            // 8 is the gap between the two children
            scrollPos = 0;
          }
        } else if (direction === "backward") {
          scrollPos -= 1;
          if (scrollPos <= 0) {
            scrollPos = scrollRef?.current.scrollWidth / 5 + (isVideo ? 0 : 8);
          }
        }
        scrollRef.current.scrollLeft = scrollPos;
        requestId = window.requestAnimationFrame(loop);
      };

      requestId = window.requestAnimationFrame(loop);

      const handleMouseDown = (event) => {
        startX = event.clientX;
        isMouseDown = true; // Set the flag to true when mouse is down
        window.cancelAnimationFrame(requestId);
      };

      const handleMouseMove = (event) => {
        if (!isMouseDown) return; // Only execute when mouse is down

        const distance = event.clientX - startX;
        scrollRef.current.scrollLeft -= distance;
        scrollPos = scrollRef?.current.scrollLeft;

        startX = event.clientX;

        if (scrollPos > scrollRef?.current.scrollWidth / 5 + (isVideo ? 0 : 8)) {
          scrollPos -= scrollRef?.current.scrollWidth / 5 + (isVideo ? 0 : 8);
        }
      };

      const handleMouseUp = () => {
        isMouseDown = false; // Set the flag to false when mouse is up
        window.cancelAnimationFrame(requestId);
        requestId = window.requestAnimationFrame(loop);
      };

      const handleMouseLeave = () => {
        isMouseDown = false; // Set the flag to false when mouse leaves
        window.cancelAnimationFrame(requestId);
        requestId = window.requestAnimationFrame(loop);
      };

      const handleTouchStart = (event) => {
        startX = event.touches[0].clientX;
        isMouseDown = true; // Set the flag to true when touch starts
        window.cancelAnimationFrame(requestId);
      };

      const handleTouchMove = (event) => {
        if (!isMouseDown) return; // Only execute when touch is active

        const distance = event.touches[0].clientX - startX;
        scrollRef.current.scrollLeft -= distance;
        scrollPos = scrollRef?.current.scrollLeft;

        startX = event.touches[0].clientX;

        if (scrollPos > scrollRef?.current.scrollWidth / 5 + (isVideo ? 0 : 8)) {
          scrollPos -= scrollRef?.current.scrollWidth / 5 + (isVideo ? 0 : 8);
        }
      };

      const handleTouchEnd = () => {
        isMouseDown = false; // Set the flag to false when touch ends
        window.cancelAnimationFrame(requestId);
        requestId = window.requestAnimationFrame(loop);
      };

      scrollRef.current.addEventListener("mousedown", dragable ? handleMouseDown : null);
      scrollRef.current.addEventListener("mousemove", dragable ? handleMouseMove : null);
      scrollRef.current.addEventListener("mouseup", dragable ? handleMouseUp : null);
      scrollRef.current.addEventListener("mouseleave", dragable ? handleMouseLeave : null);

      scrollRef.current.addEventListener("touchstart", dragable ? handleTouchStart : null);
      scrollRef.current.addEventListener("touchmove", dragable ? handleTouchMove : null);
      scrollRef.current.addEventListener("touchend", dragable ? handleTouchEnd : null);

      // Intersection Observer callback function
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            requestId = window.requestAnimationFrame(loop);
          } else {
            window.cancelAnimationFrame(requestId);
          }
        });
      };

      // Create an instance of Intersection Observer
      const observer = new IntersectionObserver(handleIntersection);

      // Observe the scrollRef element
      observer.observe(scrollRef?.current);

      return () => {
        window.cancelAnimationFrame(requestId);
        scrollRef.current?.removeEventListener("mousedown", dragable ? handleMouseDown : null);
        scrollRef.current?.removeEventListener("mousemove", dragable ? handleMouseMove : null);
        scrollRef.current?.removeEventListener("mouseup", dragable ? handleMouseUp : null);
        scrollRef.current?.removeEventListener("mouseleave", dragable ? handleMouseLeave : null);

        scrollRef.current?.removeEventListener("touchstart", dragable ? handleTouchStart : null);
        scrollRef.current?.removeEventListener("touchmove", dragable ? handleTouchMove : null);
        scrollRef.current?.removeEventListener("touchend", dragable ? handleTouchEnd : null);

        observer.disconnect(); // Disconnect the Intersection Observer
      };
    } catch (error) {
      console.log(error);
    }
  }, [direction, isVideo]);

  const handleNextButton = () => {
    if (direction === "forward") {
      if (scrollRef?.current.scrollLeft >= scrollRef?.current.scrollWidth / 5 + (isVideo ? 0 : 8)) {
        scrollRef.current.scrollLeft = 0;
      }
      scrollRef.current.scrollLeft +=
        scrollRef?.current.children[0].offsetWidth + (isVideo ? 0 : 8);
    } else if (direction === "backward") {
      if (scrollRef?.current.scrollLeft <= 0) {
        scrollRef.current.scrollLeft = scrollRef?.current.scrollWidth / 5 + (isVideo ? 0 : 8);
      }
      scrollRef.current.scrollLeft -= scrollRef.current.scrollLeft +=
        scrollRef?.current.children[0].offsetWidth + (isVideo ? 0 : 8);
    }
  };
  const handlePrevButton = () => {
    if (direction === "forward") {
      if (scrollRef?.current.scrollLeft <= 0) {
        scrollRef.current.scrollLeft = scrollRef?.current.scrollWidth / 5 + (isVideo ? 0 : 8);
      }
      scrollRef.current.scrollLeft -=
        scrollRef?.current.children[0].offsetWidth + (isVideo ? 0 : 8);
    } else if (direction === "backward") {
      if (scrollRef?.current.scrollLeft >= 0) {
        scrollRef.current.scrollLeft = scrollRef?.current.scrollWidth / 5 + (isVideo ? 0 : 8);
      }
      scrollRef.current.scrollLeft += scrollRef.current.scrollLeft +=
        scrollRef?.current.children[0].offsetWidth + (isVideo ? 0 : 8);
    }
    console.log(scrollRef?.current.scrollLeft);
  };
  return (
    <ul
      id="horizontal-scroll"
      ref={scrollRef}
      draggable="false"
      className={`relative flex flex-col flex-wrap w-full mx-auto ${
        isVideo ? "h-[50vh] gap-0" : "h-[100%] gap-4"
        // isVideo ? "h-[50vh] gap-0" : "h-[4rem] md:h-[5rem] xl:h-[6rem]gap-4"
      } overflow-hidden scrollbar-hide `.concat(className)}
    >
      {children}
      {children}
      {children}
      {children}
      {children}
      {stop ? (
        <div className="absolute w-full top-[38%] left-0">
          <button
            className={`absolute top-0 right-[1%] w-10 h-10 bg-red-500 z-20`}
            onClick={() => {
              handleNextButton();
            }}
          >
            Next
          </button>
          <button
            className={`absolute top-[0left-[1%] w-10 h-10 bg-red-500 z-20`}
            onClick={() => {
              handlePrevButton();
            }}
          >
            Prev
          </button>
        </div>
      ) : null}
    </ul>
  );
};

export default HorizontalScroll;
