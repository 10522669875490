import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { useStateStore } from "../utils/zustandStore";
import { useEffect } from "react";

const Layout = () => {
  const { languageState, setLanguageState } = useStateStore();

  const getBrowserLanguage = () => {
    const language = navigator.language || navigator.userLanguage;
    return language;
  };

  useEffect(() => {
    let browserLanguage = languageState ? languageState : getBrowserLanguage();
    switch (browserLanguage) {
      case "ko":
        setLanguageState("kr");
        break;
      case "kr":
        setLanguageState("kr");
        break;
      case "ko-KR":
        setLanguageState("kr");
        break;
      case "en":
        setLanguageState("en");
        break;
      case "en-US":
        setLanguageState("en");
        break;
      default:
        setLanguageState("en");
        break;
    }
  }, []);
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <span
        className={`scrollToTop fixed w-fit h-fit bottom-[10%] left-[88%] bg-[#4aa0d8] bg-opacity-80 p-[8px] rounded-full cursor-pointer hover:bg-opacity-50 transition-colors duration-200 z-10 scale-[.5] 2xs:scale-[.8] xs:scale-[.9] sm:scale-[1] md:scale-[1] lg:scale-[1.1] xl:scale-[1.2] 2xl:scale-[1.5] 3xl:scale-[2] 4xl:scale-[2] 5xl:scale-[2.5] 6xl:scale-[2.5] 7xl:scale-[3] 8xl:scale-[3.5]`}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <svg
          className={`h-[24px] w-[24px] text-white`}
          fill="none"
          viewBox={`0 0 24 24`}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 10l7-7m0 0l7 7m-7-7v18"
          />
        </svg>
      </span>
    </>
  );
};

export default Layout;
