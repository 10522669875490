import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Recruit from "./pages/Recruit";
import NEWS from "./pages/NEWS";
import NewsPage from "./pages/NewsPage";
import fetcher from "./utils/fetcher";
import Contents from "./pages/Contents";
import Test from "./pages/Test";
import Product from "./pages/Product";
import SmoothScroll from "./components/SmoothScroll";
import LoadingScreen from "./components/LoadingScreen";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <div>Not Found</div>,
      children: [
        {
          path: "",
          element: <Home />,
          loader: async () => {
            const response = {
              logosArr: [
                { uri: `${process.env.PUBLIC_URL}/images/logos/1-1.png`, name: "Universal Robots" },
                { uri: `${process.env.PUBLIC_URL}/images/logos/2-1.png`, name: "HanKook" },
                {
                  uri: `${process.env.PUBLIC_URL}/images/logos/3-1.png`,
                  name: "Soosan Heavy Industries",
                },
                { uri: `${process.env.PUBLIC_URL}/images/logos/4-1.png`, name: "BUREAU VERITAS" },
                {
                  uri: `${process.env.PUBLIC_URL}/images/logos/5-1.png`,
                  name: "한국 항공 우주 연구원",
                },
                { uri: `${process.env.PUBLIC_URL}/images/logos/6-1.png`, name: "JungSan" },
                { uri: `${process.env.PUBLIC_URL}/images/logos/7-1.png`, name: "Naver Cloud" },
                { uri: `${process.env.PUBLIC_URL}/images/logos/8-1.png`, name: "SEMS" },
                // { uri: `${process.env.PUBLIC_URL}/images/logos/9.svg`, name: "Airs Medical" },
                {
                  uri: `${process.env.PUBLIC_URL}/images/logos/10-1.png`,
                  name: "Seoul University",
                },
                {
                  uri: `${process.env.PUBLIC_URL}/images/logos/11-1.png`,
                  name: "Hanyang University",
                },
                {
                  uri: `${process.env.PUBLIC_URL}/images/logos/12-1.png`,
                  name: "중소 벤처 기업부",
                },
                {
                  uri: `${process.env.PUBLIC_URL}/images/logos/13-1.png`,
                  name: "중소 벤처 기업 진흥 공단",
                },
                {
                  uri: `${process.env.PUBLIC_URL}/images/logos/14-1.png`,
                  name: "서울 산업 진흥원",
                },
                {
                  uri: `${process.env.PUBLIC_URL}/images/logos/15-1.png`,
                  name: "한국 로봇 산업 진흥원",
                },
                { uri: `${process.env.PUBLIC_URL}/images/logos/16-1.png`, name: "신용 보증 기금" },
                { uri: `${process.env.PUBLIC_URL}/images/logos/17-1.png`, name: "우리 금융 그룹" },
                { uri: `${process.env.PUBLIC_URL}/images/logos/18-1.png`, name: "C-Lab" },
                {
                  uri: `${process.env.PUBLIC_URL}/images/logos/19-1.png`,
                  name: "다래 전략 사업화 센터",
                },
                { uri: `${process.env.PUBLIC_URL}/images/logos/20-1.png`, name: "성원 교역" },
              ],
            };
            return response;
          },
        },
        {
          path: "product",
          element: <Product />,
        },
        {
          path: "recruit",
          element: <Recruit />,
          loader: async () => {
            // const url = "http://localhost:3333/recruit";
            // const options = {
            //   method: "GET",
            //   headers: {
            //     "Content-Type": "application/json",
            //   },
            // };
            // const response = await fetcher(url, options);
            // return response;
            const response = {
              recruitData: [
                { title: "title", description: "description", image: "Image", index: 1 },
                { title: "title", description: "description", image: "Image", index: 2 },
                { title: "title", description: "description", image: "Image", index: 3 },
              ],
              etcData: [
                {
                  title: "title",
                  description:
                    "Contribute to an open-source project at the forefront of design technology.",
                  image: "Image",
                  index: 1,
                },
                {
                  title: "title",
                  description:
                    "Be part of a project that values transparency, collaboration, and community involvement.",
                  image: "Image",
                  index: 2,
                },
                {
                  title: "title",
                  description:
                    "Work in a fully remote setting with a diverse, global team, enjoying work for better work-life balance.",
                  image: "Image",
                  index: 3,
                },
              ],
            };
            return response;
          },
        },
        {
          path: "news",
          element: <NEWS />,
          loader: async () => {
            // const url = "http://localhost:3333/news";
            // const options = {
            //   method: "GET",
            //   headers: {
            //     "Content-Type": "application/json",
            //   },
            // };
            // const response = await fetcher(url, options);
            // return response;
            const response = {
              newsData: [
                {
                  title: "포탈301, 다래전략사업화센터로부터 시드 투자 유치",
                  date: "2023-08-16",
                  categories: ["NEWS"],
                  thumbnail:
                    "https://img.etnews.com/news/article/2023/08/16/news-p.v1.20230816.a4093efa849342aea4c144885bb75a8d_P1.png",
                  link: "https://www.etnews.com/20230816000109",
                  index: 1,
                },
                {
                  title: "[Soft Wave 2023] 포탈301, AI기반 학습 로봇 솔루션 선보여",
                  date: "2023-12-01",
                  categories: ["NEWS"],
                  thumbnail: "http://file.yeogie.com/img.Editor/202312/PdH3O8SXYN.jpg",
                  link: "http://robotzine.co.kr/entry/268119",
                  index: 2,
                },
                {
                  title: "유니버설뱅킹앱으로 서비스 차별화",
                  date: "2024-06-05",
                  categories: ["NEWS"],
                  thumbnail:
                    "https://newsimg-hams.hankookilbo.com/2024/06/05/fb87f0bb-5ae5-4037-9bd4-c88065dab09c.jpg",
                  link: "https://www.hankookilbo.com/News/Read/A2024060514180004076?did=NA",
                  index: 3,
                },
                {
                  title: "AI와 만나 산업 현장에서 작업 정밀도 높이는 로봇 솔루션",
                  date: "2024-03-29",
                  categories: ["NEWS"],
                  thumbnail: "https://newsimg.sedaily.com/2024/03/29/2D6TPQCJJ7_1.jpg",
                  link: "https://www.sedaily.com/NewsView/2D6TPQCJJ7",
                  index: 4,
                },
              ],
            };
            return response;
          },
        },
        {
          path: "news/pages",
          element: <NewsPage />,
          loader: async () => {
            const response = {
              data: "here is news page",
            };
            return response;
          },
        },
        {
          path: "contents",
          element: <Contents />,
        },
        {
          path: "test",
          element: <Test />,
        },
      ],
    },
  ]);

  return (
    <RouterProvider router={router}>
      <SmoothScroll>
        <Layout />
        <LoadingScreen />
      </SmoothScroll>
    </RouterProvider>
  );
}

export default App;
