/**
 * @param {object} props - The props object.
 * @param {number} props.distance - The distance the element will move during the fade in animation.
 * @param {string} props.directionFrom - The direction from which the element will move during the fade in animation. Possible values are "up", "down", "left", and "right".
 * @param {number} props.speed - The speed of the fade in animation.
 * @param {ReactNode} props.children - The children to be rendered inside the component.
 * @returns
 */
const FadeIn = ({ distance, directionFrom, speed, children, ...props }) => {
  const handleIntersect = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.style.transition = `opacity ${speed}s, transform ${speed}s`;
        entry.target.style.opacity = 1;
        entry.target.style.transform = `${
          directionFrom === "up" || directionFrom === "down" ? "translateY" : "translateX"
        }(0)`;
        observer.unobserve(entry.target);
      }
    });
  };

  const observer = new IntersectionObserver(handleIntersect, {
    threshold: 0.5,
  });

  return (
    <div
      className={`flex items-center w-full relative mx-auto lg:mx-0 `.concat(props.className || "")}
      ref={(ref) => {
        if (ref) {
          ref.style.transition = `opacity ${speed}s, transform ${speed}s`;
          ref.style.opacity = 0;
          ref.style.transform = `${
            directionFrom === "up"
              ? "translateY"
              : directionFrom === "right"
              ? "translateX"
              : directionFrom === "left"
              ? "translateX"
              : "translateY"
          }(${directionFrom === "up" || directionFrom === "left" ? "-" : ""}${distance}px)`;
          observer.observe(ref);
        }
      }}
    >
      {children}
    </div>
  );
};

export default FadeIn;
